import React from "react";
import ErrorMessage from "./ErrorMessage";
import ExpiredChallenge from "./ExpiredChallenge";
//import TweetChallenge from "./TweetChallenge";
import TweetBox from "../../../components/TweetBox/TweetBox";

const SocialChallenge: React.FC<any> = ({
  challengeBySlug,
  submitChallenge,
  errorMessage,
}) => {
  return (
    <>
      {challengeBySlug.data.active ? (
        <div className="d-flex flex-column action-wrap">
          {challengeBySlug.data.data &&
          challengeBySlug.data.type != "follow" ? (
            <TweetBox tweet={challengeBySlug.data.data} />
          ) : null}
          <button
            onClick={() => submitChallenge()}
            className="challenge-submit d-flex justify-content-center align-content-center"
            disabled={challengeBySlug.data.completed}
          >
            {challengeBySlug.data.type === "like" ? (
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6316 67.3684H59.84L67.3684 59.84V35.0877H51.2281C47.7398 35.0877 44.9123 32.2602 44.9123 28.7719V12.6316H20.16L12.6316 20.16V67.3684ZM62.4561 80H6.31579C2.82756 80 0 77.1724 0 73.6842V17.5439C0 15.8685 0.665263 14.2625 1.84982 13.0779L13.0779 1.84982C14.2625 0.665263 15.8685 0 17.5439 0H51.2281C54.7162 0 57.5439 2.82756 57.5439 6.31579V22.4561H73.6842C77.1723 22.4561 80 25.2837 80 28.7719V62.4561C80 64.131 79.3347 65.738 78.1502 66.9221L66.9221 78.1502C65.7375 79.3347 64.1315 80 62.4561 80Z"
                  fill="#000000"
                />
              </svg>
            ) : null}
            {challengeBySlug.data.type === "follow" ? (
              <svg
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9008 20.9007L1 1"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M1.51953 21.0012L21.0032 21.0012L21.0032 1.51758"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            ) : null}
            {challengeBySlug.data.type === "retweet" ? (
              <svg
                width="111"
                height="80"
                viewBox="0 0 111 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.5635 46.3158C23.1799 46.3158 24.7963 45.6991 26.0295 44.4659L43.127 27.3684L34.1951 18.4365L27.8793 24.7523V17.353L32.6007 12.6316H82.6161V29.4737H95.2477V6.31579C95.2477 2.82756 92.4201 0 88.9319 0H29.9845C28.3092 0 26.7031 0.665263 25.5186 1.84982L17.0975 10.2709C15.913 11.4554 15.2477 13.0615 15.2477 14.7368V24.7523L8.93193 18.4365L0 27.3684L17.0975 44.4659C18.3307 45.6991 19.9471 46.3158 21.5635 46.3158Z"
                  fill="#000000"
                />
                <path
                  d="M110.494 52.632L93.3962 35.5344C90.9303 33.068 86.9308 33.068 84.4643 35.5344L67.3668 52.632L76.2987 61.5639L82.6145 55.2481V62.6474L77.8931 67.3688H27.8777V50.5267H15.2461V73.6846C15.2461 77.1728 18.0738 80.0004 21.5619 80.0004H80.5092C82.1846 80.0004 83.7906 79.3351 84.9752 78.1506L93.3962 69.7295C94.5808 68.5454 95.2461 66.9384 95.2461 65.2635V55.2481L101.562 61.5639L110.494 52.632Z"
                  fill="#000000"
                />
              </svg>
            ) : null}
            {challengeBySlug.data.type}
          </button>
          <ErrorMessage errorMessage={errorMessage} />
        </div>
      ) : (
        <ExpiredChallenge />
      )}
    </>
  );
};

export default SocialChallenge;
