import React from "react";
import paperclipIcon from "../../../assets/img/icons/Paperclip.svg";

const TweetFileUpload: React.FC<any> = ({ getUploadedFile }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    getUploadedFile(event.target.files[0]);
  };
  return (
    <>
      <a onClick={handleClick} style={{ cursor: "pointer" }}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.75 0H2.25C1.01 0 0 1.01 0 2.25V17.75C0 18.99 1.01 20 2.25 20H17.75C18.99 20 20 18.99 20 17.75V2.25C20 1.01 18.99 0 17.75 0ZM2.25 1.5H17.75C18.163 1.5 18.5 1.837 18.5 2.25V11.926L14.642 8.068C14.502 7.928 14.312 7.848 14.112 7.848H14.109C13.909 7.848 13.716 7.928 13.577 8.072L9.26 12.456L7.447 10.65C7.307 10.51 7.117 10.43 6.917 10.43C6.724 10.4 6.522 10.51 6.382 10.657L1.5 15.642V2.25C1.5 1.837 1.837 1.5 2.25 1.5ZM1.506 17.78L6.924 12.246L13.206 18.5H2.25C1.848 18.5 1.523 18.178 1.506 17.78ZM17.75 18.5H15.33L10.323 13.513L14.115 9.663L18.5 14.047V17.75C18.5 18.163 18.163 18.5 17.75 18.5Z"
            fill="white"
          />
          <path
            d="M6.86817 7.8506C7.71979 7.8506 8.41017 7.16022 8.41017 6.3086C8.41017 5.45698 7.71979 4.7666 6.86817 4.7666C6.01655 4.7666 5.32617 5.45698 5.32617 6.3086C5.32617 7.16022 6.01655 7.8506 6.86817 7.8506Z"
            fill="white"
          />
        </svg>
      </a>
      <input
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default TweetFileUpload;
