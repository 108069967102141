import React from "react";
import { useHistory } from "react-router-dom";

const SubmitChallenge: React.FC<any> = () => {
  const history = useHistory();
  return (
    <div className="challenge-submitted d-flex justify-content-center align-content-center flex-column box p-5 mt-5">
      <h2>congratulations!</h2>
      <p>Challenge submitted succesfully</p>
      <svg
        width="111"
        height="74"
        viewBox="0 0 111 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.9995 36.9999C72.9995 17.131 56.8685 1 36.9997 1C17.1309 1 1 17.131 1 36.9999C1 56.869 17.1309 73 36.9997 73C56.8685 73 72.9995 56.869 72.9995 36.9999Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.5816 36.9999C80.5816 17.131 64.4506 1 44.5818 1C24.713 1 8.58203 17.131 8.58203 36.9999C8.58203 56.869 24.713 73 44.5818 73C64.4506 73 80.5816 56.869 80.5816 36.9999Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.6889 36.9999C87.6889 17.131 71.5579 1 51.6891 1C31.8203 1 15.6895 17.131 15.6895 36.9999C15.6895 56.869 31.8203 73 51.6891 73C71.5579 73 87.6889 56.869 87.6889 36.9999Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.7964 36.9999C94.7964 17.131 78.6655 1 58.7967 1C38.9278 1 22.7969 17.131 22.7969 36.9999C22.7969 56.869 38.9278 73 58.7967 73C78.6655 73 94.7964 56.869 94.7964 36.9999Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.376 36.9999C102.376 17.131 86.2455 1 66.3767 1C46.508 1 30.377 17.131 30.377 36.9999C30.377 56.869 46.508 73 66.3767 73C86.2455 73 102.376 56.869 102.376 36.9999Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.484 36.9999C109.484 17.131 93.3529 1 73.4841 1C53.6153 1 37.4844 17.131 37.4844 36.9999C37.4844 56.869 53.6153 73 73.4841 73C93.3529 73 109.484 56.869 109.484 36.9999Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
      <button
        onClick={() => history.push(`/challenges`)}
        className="button yellow"
      >
        KEEP PLAYING
      </button>
    </div>
  );
};

export default SubmitChallenge;
