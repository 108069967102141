import React, { useEffect } from "react";
import useOverdrive from "../hooks/useOverdrive";
import { useHistory } from "react-router-dom";

function withAuthentication(WrappedComponent: React.FC) {
  return function WithAuthenticationComponent({ ...props }) {
    const { overdrive } = useOverdrive();
    const history = useHistory();

    useEffect(() => {
      if (!overdrive.jwt) {
        history.push("/");
      }
    }, [overdrive.jwt]);

    return <WrappedComponent {...props} />;
  };
}

export default withAuthentication;
