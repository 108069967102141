import axios from "axios";
import { useContext } from "react";
import { ApiContext } from "../context/ApiProvider";

//TODO remove MockImages
import image1 from "../containers/Marketplace/1.jpg";
import image2 from "../containers/Marketplace/2.jpg";
import image3 from "../containers/Marketplace/3.jpg";

const METADATA_URI = "https://pepemon.finance/api/cards/";
export const ALL_CARDS = [1, 2, 3];
export const SHOP_CARDS = [1, 2, 3];
const useMetadataApi = () => {
  const [apiData, dispatchApi] = useContext(ApiContext);
  const getCardMetadata = async (cardId: string) => {
    if (
      apiData["metadata"] &&
      apiData["metadata"][cardId] &&
      apiData["metadata"][cardId].status === "pending"
    ) {
      return;
    }

    try {
      dispatchApi({
        type: "metadata",
        id: cardId,
        status: "pending",
      });

      const response = await axios.get(`${METADATA_URI}${cardId}`);

      //TODO remove overwrite MockImages
      response.data.image =
        cardId === "1" ? image1 : cardId === "2" ? image2 : image3;

      const action = {
        type: "metadata",
        id: cardId,
        status: "completed",
        data: response.data,
      };

      dispatchApi(action);
    } catch (err) {
      dispatchApi({
        type: "metadata",
        id: cardId,
        status: "failed",
        error: err,
      });
    }
  };

  return {
    apiData,
    fetchAllMetadata: () => {
      ALL_CARDS.map((cardId: number) => getCardMetadata(cardId.toString()));
    },
  };
};

export default useMetadataApi;
