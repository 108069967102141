import React from "react";
import "./Rankings.scss";
import positionTop from "./position-top.svg";

const RankingsTable: React.FC<any> = ({ RankingsData }) => {
  return (
    <table className="table ranking-table">
      <thead>
        <tr>
          <th>Position</th>
          <th>Player</th>
          <th>Level</th>
          <th style={{ textAlign: "right" }}>Reputation</th>
          <th style={{ textAlign: "right" }}>Followers</th>
          <th style={{ textAlign: "right" }}>Tweets</th>
        </tr>
      </thead>
      <tbody>
        {RankingsData.map((value: any, index: any) => {
          return (
            <tr key={value.id}>
              <td>
                <div className="d-flex flex-row position align-items-center">
                  {index + 1}
                  {/*<div className="position-changes d-flex flex-column justify-content-center ml-3">*/}
                  {/*  <img src={positionTop} /> <span className="mt-1">+1</span>*/}
                  {/*</div>*/}
                </div>
              </td>
              <td>
                <div className="player d-flex flex-row align-items-center">
                  <div className="image-wrap">
                    <img src={value.profile.photoUrl} />
                  </div>
                  <div className="details-wrap pl-3">
                    <div className="name">{value.profile.displayName}</div>
                    <div className="username">@{value.profile.screenName}</div>
                  </div>
                </div>
              </td>
              <td>
                <div className="level">{value.level}</div>
              </td>
              <td>
                <div className="reputation">{value.reputation}</div>
              </td>
              <td>
                <div className="followers">{value.profile.followers}</div>
              </td>
              <td>
                <div className="tweets">{value.tweets}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default RankingsTable;
