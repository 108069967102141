import React from "react";
import useResizeWindow from "../../hooks/useResizeWindow";

const MobileOverlay: React.FC<any> = ({ children }) => {
  const { windowWidth } = useResizeWindow();
  return (
    <>
      {windowWidth > 992 || document.location.hash === "#off" ? (
        <>{children}</>
      ) : (
        <div className="MobileOverlay d-flex justify-content-center align-self-center align-items-center align-content-center flex-column">
          <svg
            width="575"
            height="64"
            viewBox="0 0 575 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M77.8906 0H95.6392L113.312 47.7627H115.368L132.967 0H150.715L126.339 63.9891H102.268L77.8906 0Z"
              fill="white"
            />
            <path
              d="M154.525 0H209.678V13.9406H171.743V25.9759H208.308V37.936H171.743V50.0484H209.678V63.9891H154.525V0Z"
              fill="white"
            />
            <path
              d="M232.998 32.756H249.376C256.308 32.756 260.04 29.5566 260.04 23.5386C260.04 17.52 256.308 14.321 249.376 14.321H232.998V32.756ZM261.945 63.9886L252.119 45.7822H232.998V63.9886H215.781V0H250.442C267.735 0 277.181 7.8462 277.181 23.3857C277.181 32.2227 272.992 38.6218 266.821 42.2781L278.933 63.9886H261.945Z"
              fill="white"
            />
            <path
              d="M371.106 32.756H387.483C394.415 32.756 398.148 29.5566 398.148 23.5386C398.148 17.52 394.415 14.321 387.483 14.321H371.106V32.756ZM400.053 63.9886L390.226 45.7822H371.106V63.9886H353.889V0H388.549C405.842 0 415.288 7.8462 415.288 23.3857C415.288 32.2227 411.099 38.6218 404.928 42.2781L417.04 63.9886H400.053Z"
              fill="white"
            />
            <path d="M438.977 0H421.76V63.9891H438.977V0Z" fill="white" />
            <path
              d="M442.785 0H460.534L478.207 47.7627H480.263L497.861 0H515.61L491.234 63.9891H467.162L442.785 0Z"
              fill="white"
            />
            <path
              d="M519.42 0H574.573V13.9406H536.637V25.9759H573.203V37.936H536.637V50.0484H574.573V63.9891H519.42V0Z"
              fill="white"
            />
            <path
              d="M0 45.6099V26.3027C0 23.626 1.0421 21.1096 2.93451 19.2186L19.2178 2.9345C21.0824 1.07008 23.6637 0 26.3019 0H59.001L64.0144 18.3805H29.7742L18.3799 29.7697V45.6099H0Z"
              fill="white"
            />
            <path
              d="M23.363 64.0009L18.3496 45.6222H52.6248L64.0191 34.2324V18.4155H82.3984V37.7002C82.3984 40.3761 81.3569 42.8906 79.4658 44.7837L63.1811 61.0676C61.316 62.9309 58.7341 64.0009 56.0978 64.0009H23.363Z"
              fill="white"
            />
            <path
              d="M281.557 18.3215V0H325.916L330.914 18.3215H281.557Z"
              fill="white"
            />
            <path
              d="M281.557 63.984V45.6624H319.555L330.927 34.2953L330.914 18.3164H349.248V37.7513C349.248 40.4188 348.209 42.9268 346.323 44.8135L330.078 61.0585C328.219 62.9186 325.644 63.984 323.016 63.984H281.557Z"
              fill="white"
            />
          </svg>
          <p>Mobile version coming soon</p>
        </div>
      )}
    </>
  );
};

export default MobileOverlay;
