import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { WalletProvider } from "./context/WalletProvider";
import { TransactionProvider } from "./context/TransactionProvider";
import { FirebaseProvider } from "./context/FirebaseProvider";
import { OverdriveApiProvider } from "./context/OverdriveApiProvider";
import { OverdriveProvider } from "./context/OverdriveProvider";
import { ThemeProvider } from "styled-components";
import ModalProvider from "./context/ModalProvider";
import withAuthentication from "./hocs/withAuthentication";
import ScoreProvider from "./context/ScoreProvider";
import AnimationProvider from "./context/AnimationProvider";

import theme from "./theme/theme";
import Home from "./containers/Home/Home";
import { Login } from "./containers/Login/Login";
import { Body } from "./components";
import Campaigns from "./containers/Campaigns/Campaigns";
import Campaign from "./containers/Campaign/Campaign";
import Profile from "./containers/Profile/Profile";
import Marketplace from "./containers/Marketplace/Marketplace";
import Dashboard from "./containers/Dashboard/Dashboard";
import Challenges from "./containers/Challenges/Challenges";
import Challenge from "./containers/Challenge/Challenge";
import Product from "./containers/Product/Product";
import Demo from "./containers/Demo/Demo";
import Rankings from "./containers/Rankings/Rankings";
import withPage from "./hocs/withPage";
import { ApiProvider } from "./context/ApiProvider";
import MyNFTs from "./containers/MyNFTS";
import MobileOverlay from "./containers/MobileOverlay/MobileOverlay";

const HomeWithAuth = withAuthentication(Home);

function App() {
  return (
    <Providers>
      <Body>
        <Router>
          <Switch>
            <Route path="/campaigns" component={withPage(Campaigns)} />
            <Route path="/campaign/:slug" component={withPage(Campaign)} />
            <Route path="/challenges" component={withPage(Challenges)} />
            <Route path="/challenge/:slug" component={withPage(Challenge)} />
            <Route path="/rankings" component={withPage(Rankings)} />
            <Route path="/profile" component={withPage(Profile)} />
            <Route path="/marketplace" component={withPage(Marketplace)} />
            <Route path="/product/:id" component={withPage(Product)} />
            <Route path="/my-nfts" component={withPage(MyNFTs)} />
            <Route path="/demo" component={withPage(Demo)} />
            <Route path="/test-auth" component={HomeWithAuth} />
            <Route path="/test" component={Home} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/" component={Login} />
          </Switch>
        </Router>
      </Body>
    </Providers>
  );
}

const Providers: React.FC<any> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <OverdriveProvider>
        <OverdriveApiProvider>
          <ApiProvider>
            <WalletProvider>
              <FirebaseProvider>
                <TransactionProvider>
                  <ScoreProvider>
                    <AnimationProvider>
                      <MobileOverlay>
                        <ModalProvider>{children}</ModalProvider>
                      </MobileOverlay>
                    </AnimationProvider>
                  </ScoreProvider>
                </TransactionProvider>
              </FirebaseProvider>
            </WalletProvider>
          </ApiProvider>
        </OverdriveApiProvider>
      </OverdriveProvider>
    </ThemeProvider>
  );
};

export default App;
