import React from "react";
import styled, { keyframes } from "styled-components";

export interface ModalProps {
  onDismiss?: () => void;
  style?: any;
}

const Modal: React.FC<any> = ({ children, style }) => {
  return (
    <StyledResponsiveWrapper>
      <StyledModal style={style}>{children}</StyledModal>
    </StyledResponsiveWrapper>
  );
};

const mobileKeyframes = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const StyledResponsiveWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  //max-width: 60vw;
  width: 100%;
`;

const StyledModal = styled.div<any>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "rgba(58, 72, 107, 0.3)"};
  backdrop-filter: ${(props) => props.backdropFilter};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 0;
  z-index: 1;
  padding: ${(props) => props.padding};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  min-width: ${(props) => props.minWidth};
  width: 100%;
  padding: 15px;
`;

export default Modal;
