import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TopBarDefault from "./TopBarDefault";
import TopBarCampaign from "./TopBarCampaign";
import TopBarCampaignV2 from "./TopBarCampaignV2";
import { Context } from "../../context/ModalProvider";
import useWalletProvider from "../../hooks/useWalletProvider";
import useOverdrive from "../../hooks/useOverdrive";
import useWeb3Modal from "../../hooks/useWeb3Modal";
import useModal from "../../hooks/useModal";
import InfoModal from "../../components/InfoModal";
import { isSameAddress } from "../../utils/wallet";
import { auth } from "../../services/firebase";
//import useApi from "../../hooks/useMetadataApi";
import useTransaction from "../../hooks/useTransaction";
import TransactionModal from "../../components/TransactionModal";
//import config from "../../config/config.prod";

const TopBar: React.FC<any> = () => {
  const modalContext = useContext(Context);
  const { wallet } = useWalletProvider();
  const { overdrive } = useOverdrive();
  const history = useHistory();
  const [, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const getAuth = auth.getAuth();
  const { dispatchOd } = useOverdrive();
  //const { fetchAllMetadata, apiData } = useApi();
  const { transaction } = useTransaction();
  const logoutOverdrive = () => {
    logoutOfWeb3Modal();
    getAuth.signOut();
    dispatchOd({
      type: "logout",
    });
    history.push("/");
  };

  const switchToFantom = () => {
    const ethereum = window.ethereum;
    const data = [
      {
        chainId: "0xFA",
        chainName: "Fantom",
        rpcUrls: ["https://rpc.ftm.tools/"],
        blockExplorerUrls: ["https://ftmscan.com/"],
      },
    ];
    console.log(data);
    ethereum
      .request({ method: "wallet_addEthereumChain", params: data })
      .then((response: any) => {
        //console.log(response);
      })
      .catch();
  };
  const switchToAvalanche = () => {
    const ethereum = window.ethereum;
    const data = [
      {
        chainId: "0xA86A",
        chainName: "Avalanche",
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
      },
    ];
    ethereum
      .request({ method: "wallet_addEthereumChain", params: data })
      .then((response: any) => {
        //console.log(response);
      })
      .catch();
  };

  const [onPresentTransactionModal] = useModal(
    <TransactionModal />,
    "transaction-status-modal"
  );
  const [onPresentWrongAccountModal, onDismissWrongAccountModal] = useModal(
    <InfoModal
      message={`[MetaMask] Wrong wallet selected. Please change to ${wallet.account}`}
      actionButton={() => logoutOverdrive()}
      actionButtonText="Logout"
    />,
    "wrong-account-modal"
  );
  const [onPresentWrongNetworkModal, onDismissWrongNetworkModal] = useModal(
    <InfoModal
      message={`[MetaMask] Wrong network selected. Please change network to ${
        [250, 4113].indexOf(wallet.chainId) - 1 ? "Fantom or Avalanche" : ""
      }`}
      actionButton={() => switchToFantom()}
      actionButtonText="Switch to Fantom"
      actionButton2={() => switchToAvalanche()}
      actionButtonText2="Switch to Avalanche"
    />,
    "wrong-network-modal"
  );

  useEffect(() => {
    if (transaction.id) {
      onPresentTransactionModal();
    }
  }, [transaction]);

  useEffect(() => {
    if (
      wallet.web3NetworkSelected &&
      parseInt(wallet.chainId) !== parseInt(wallet.web3NetworkSelected)
    ) {
      return onPresentWrongNetworkModal();
    } else if (
      wallet.web3NetworkSelected &&
      parseInt(wallet.chainId) === parseInt(wallet.web3NetworkSelected) &&
      modalContext.modalKey === "wrong-network-modal"
    ) {
      return onDismissWrongNetworkModal();
    }
  }, [wallet.chainId, wallet.web3NetworkSelected, modalContext.isOpen]);

  useEffect(() => {
    if (
      wallet.web3AccountSelected &&
      !isSameAddress(wallet.web3AccountSelected, wallet.account)
    ) {
      onPresentWrongAccountModal();
    } else if (
      wallet.web3AccountSelected &&
      isSameAddress(wallet.web3AccountSelected, wallet.account) &&
      modalContext.modalKey === "wrong-account-modal"
    ) {
      onDismissWrongAccountModal();
    }
  }, [wallet.address, wallet.web3AccountSelected, modalContext.isOpen]);

  useEffect(() => {
    if (overdrive.jwt && !wallet.account) {
      loadWeb3Modal();
    }
  }, [wallet.account, wallet.web3AccountSelected, overdrive.jwt]);

  // Fetch all card metadata
  // useEffect(() => {
  //   fetchAllMetadata();
  // }, []);

  const getHeaderTemplate = (location: any) => {
    if (location.pathname.indexOf("campaign/") > -1) {
      return <TopBarCampaignV2 />;
    } else {
      return <TopBarDefault />;
    }
  };

  const location = useLocation();
  return getHeaderTemplate(location);
};

export default TopBar;
