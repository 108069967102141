export const white = "#FFF";
export const black = "#000";

export const grey = {
  100: "#f8f8f8",
  200: "#f1f1f1",
  300: "#c4b9b7",
  400: "#6c726c",
  500: "#4c514c",
  600: "#221f1f",
  800: "#131111",
};
