import React, { useEffect, useState } from "react";
import {
  Box1,
  FlexColumn,
  FlexRow,
  Header1,
  Header2,
  Header3,
  Typo2,
} from "../../components";
import styled from "styled-components";
import Spacer from "../../components/Spacer";
import LikeIcon from "../../assets/img/icons/LikeSmall.svg";
import RetweetIcon from "../../assets/img/icons/RetweetSmall.svg";
import CommentIcon from "../../assets/img/icons/CommentSmall.svg";
import TwitterSpeechIcon from "../../assets/img/icons/TwitterSpeechSmall.svg";
import balanceIcon from "../../assets/img/icons/Subtract.svg";
import useOverdriveContract, {
  OD_BOOST_STAKE_METHODS,
  OD_FACTORY_METHODS,
} from "../../hooks/useOverdriveContract";
import useWalletProvider from "../../hooks/useWalletProvider";
import useApi, { ALL_CARDS } from "../../hooks/useMetadataApi";
import { formatHexToBN } from "../../utils/conversions";
import { BigNumber } from "@ethersproject/bignumber";
import { send } from "../../utils/contract";
import useTransaction from "../../hooks/useTransaction";
// @ts-ignore
import { addresses } from "@overdrive/contracts";
import config from "../../config/config.prod";

const profile = {
  photoUrl:
    "https://pbs.twimg.com/profile_images/1304416263293349889/fs5yUamX_400x400.jpg",
  displayName: "KriptoJohny",
};

const ProfileHeader: React.FC<any> = ({ profile }) => {
  return (
    <FlexColumn alignItems="center">
      <img
        style={{ height: "100px", width: "100px", borderRadius: "50%" }}
        alt="profile picture"
        src={profile.photoUrl}
      />
      <Header2 style={{ paddingTop: "1rem" }}>{profile.displayName}</Header2>
      <a style={{ paddingTop: "1rem", cursor: "pointer" }}>
        <Typo2 style={{ color: "#3590FF" }}>Edit Profile</Typo2>
      </a>
    </FlexColumn>
  );
};

const ProfileLevel: React.FC<any> = ({ current, percentage }) => {
  return (
    <div>
      <FlexRow>
        <Header2 style={{ fontSize: "22px", paddingRight: ".2rem" }}>
          {current}
        </Header2>
        <Header3 style={{ marginTop: "auto" }}>Level</Header3>
        <Header2 style={{ marginLeft: "auto", fontSize: "22px" }}>{`${
          parseInt(current) + 1
        }`}</Header2>
      </FlexRow>
      <Spacer size="sm" />
      <LevelCounter percentage={percentage} />
    </div>
  );
};

const LevelCounter: React.FC<any> = ({ percentage }) => {
  const totalWidth = 20;
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <StyledLevelBar
        style={{
          width: `${totalWidth}rem`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          backgroundColor: "rgba(24, 25, 33, 0.8)",
          height: "1.2rem",
          borderRadius: "20px",
        }}
      >
        <StyledFilledLevelBar
          isZero={percentage === 0}
          style={{
            background: "linear-gradient(135deg, #0eace7 0%, #086eeb 100%)",
            width: `${totalWidth * (percentage / 100) - 0.2}rem`,
            height: "1.2rem",
            position: "relative",
            borderRadius: "20px",
          }}
        />
      </StyledLevelBar>
    </div>
  );
};

const StyledLevelBar = styled.div``;
const StyledFilledLevelBar = styled.div<any>``;

const ProfileNextLevelReq: React.FC<any> = ({
  likesReq,
  likes,
  commentsReq,
  comments,
  retweetsReq,
  retweets,
  tweetsReq,
  tweets,
}) => {
  return (
    <FlexColumn>
      <div
        style={{ paddingBottom: ".5rem", borderBottom: "1px solid #3A486B" }}
      >
        <Header3 style={{ color: "white" }}>Request for next level</Header3>
      </div>
      <Spacer />
      <FlexRow>
        <ActionButtonRightTop
          background={"linear-gradient(135deg, #EF4098 0%, #DF1451 100%)"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={LikeIcon} />
        </ActionButtonRightTop>
        <FlexRow alignItems="center" style={{ flex: 1 }}>
          <Header3 style={{ fontSize: "20px", paddingLeft: "1rem" }}>
            Likes
          </Header3>
          <Header2
            style={{ fontSize: "20px", marginLeft: "auto" }}
          >{`${likes} / ${likesReq}`}</Header2>
        </FlexRow>
      </FlexRow>
      <Spacer size="sm" />
      <FlexRow>
        <ActionButtonRightTop
          background={
            "linear-gradient(157.58deg, #33AC38 14.6%, #19691C 97.44%)"
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={CommentIcon} />
        </ActionButtonRightTop>
        <FlexRow alignItems="center" style={{ flex: 1 }}>
          <Header3 style={{ fontSize: "20px", paddingLeft: "1rem" }}>
            Comments
          </Header3>
          <Header2
            style={{ fontSize: "20px", marginLeft: "auto" }}
          >{`${comments} / ${commentsReq}`}</Header2>
        </FlexRow>
      </FlexRow>
      <Spacer size="sm" />
      <FlexRow>
        <ActionButtonRightTop
          background={"linear-gradient(135deg, #A240EF 0%, #3014DF 100%)"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={RetweetIcon} />
        </ActionButtonRightTop>
        <FlexRow alignItems="center" style={{ flex: 1 }}>
          <Header3 style={{ fontSize: "20px", paddingLeft: "1rem" }}>
            Retweets
          </Header3>
          <Header2
            style={{ fontSize: "20px", marginLeft: "auto" }}
          >{`${retweets} / ${retweetsReq}`}</Header2>
        </FlexRow>
      </FlexRow>
      <Spacer size="sm" />
      <FlexRow>
        <ActionButtonRightTop
          background={
            "linear-gradient(139.34deg, #FFB800 9.33%, #695112 100.47%)"
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={TwitterSpeechIcon} />
        </ActionButtonRightTop>
        <FlexRow alignItems="center" style={{ flex: 1 }}>
          <Header3 style={{ fontSize: "20px", paddingLeft: "1rem" }}>
            Tweets
          </Header3>
          <Header2
            style={{ fontSize: "20px", marginLeft: "auto" }}
          >{`${tweets} / ${tweetsReq}`}</Header2>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
};

interface ActionButtonProps {
  background: any;
  border?: any;
}

const ActionButtonRightTop = styled.button<ActionButtonProps>`
  height: 3rem;
  width: 3rem;
  position: relative;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: any) => props.background};
  cursor: pointer;
  outline: none;

  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 6px solid #1f2030;
    border-left: 6px solid transparent;
    width: 0;
  }

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 6px solid #1f2030;
    border-right: 6px solid transparent;
    width: 0;
  }
`;

export const NFTView: React.FC<any> = ({ nft }) => {
  const [isStaked, setIsStaked] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [isUnstaking, setIsUnstaking] = useState(false);
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const { wallet } = useWalletProvider();
  const {
    callODBoostStakeMethod,
    callODFactoryMethod,
  } = useOverdriveContract();
  const { dispatchTx } = useTransaction();

  const rarity =
    nft &&
    nft.metadata &&
    nft.metadata.attributes &&
    nft.metadata.attributes.find((attr: any) => attr.trait_type === "Rarity")
      .value;

  useEffect(() => {
    if (
      wallet.account &&
      config.supportedChains.includes(parseInt(wallet.chainId)) &&
      parseInt(wallet.chainId) === parseInt(wallet.web3NetworkSelected)
    ) {
      callODFactoryMethod(OD_FACTORY_METHODS.IS_APPROVED_FOR_ALL, [
        wallet.account,
        addresses[wallet.chainId].overdriveBoostStake,
      ]).then((result) => setIsApprovedForAll(result));
      callODBoostStakeMethod(OD_BOOST_STAKE_METHODS.GET_STAKED_CARDS, [
        wallet.account,
      ]).then((result: BigNumber[]) => {
        if (result.map((item) => item.toNumber()).includes(nft.cardId)) {
          setIsStaked(true);
        }
      });
    }
  }, [wallet.account, wallet.chainId, isApproving]);

  const handleApproveForAll = async () => {
    setIsApproving(true);

    send(
      wallet.provider,
      () =>
        callODFactoryMethod(OD_FACTORY_METHODS.SET_APPROVED_FOR_ALL, [
          addresses[wallet.chainId].overdriveBoostStake,
        ]),
      dispatchTx
    )
      .then(() => {
        setIsApproving(false);
      })
      .catch((err) => {
        setIsApproving(false);
        console.log(err);
      });
  };

  const handleStakeCard = async () => {
    setIsStaking(true);

    send(
      wallet.provider,
      () => callODBoostStakeMethod(OD_BOOST_STAKE_METHODS.STAKE, [nft.cardId]),
      dispatchTx
    )
      .then((result) => {
        setIsStaking(false);

        if (result) {
          setIsStaked(true);
        }
      })
      .catch((err) => {
        setIsStaking(false);
        console.log(err);
      });
  };

  const handleUnstakeCard = async () => {
    setIsUnstaking(true);

    send(
      wallet.provider,
      () =>
        callODBoostStakeMethod(OD_BOOST_STAKE_METHODS.UNSTAKE, [nft.cardId]),
      dispatchTx
    )
      .then((result) => {
        setIsUnstaking(false);
        if (result) {
          setIsStaked(false);
        }
      })
      .catch((err) => {
        setIsUnstaking(false);
        console.log(err);
      });
  };

  return (
    <Box1
      style={{
        height: "100%",
        width: "32%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: ".5rem",
          background: `url(${nft.metadata.image}) no-repeat center center`,
          backgroundSize: "contain",
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "auto",
            padding: ".5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Header2 style={{ padding: ".2rem 0" }}>{nft.metadata.name}</Header2>
          <Header3>{rarity}</Header3>
        </div>
      </div>
      <div
        style={{
          height: "5rem",
          marginTop: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 1.5rem 0 1rem",
        }}
      >
        {isApprovedForAll ? (
          <Button
            isStaked={isStaked}
            style={{
              width: "8rem",
              height: "2.5rem",
            }}
            onClick={() => (isStaked ? handleUnstakeCard() : handleStakeCard())}
          >
            {isStaked
              ? isUnstaking
                ? "Unstaking..."
                : "Unstake"
              : isStaking
              ? "Staking..."
              : "Stake"}
          </Button>
        ) : (
          <Button onClick={handleApproveForAll} isStaked={false}>
            {isApproving ? "Approving..." : "Approve"}
          </Button>
        )}
        <FlexRow>
          <img
            src={balanceIcon}
            style={{ width: "1.4rem", height: "1.4rem", paddingLeft: ".8rem" }}
          />
          <Header3
            style={{ fontSize: "16px", fontWeight: 700, paddingLeft: ".4rem" }}
          >
            6200
          </Header3>
        </FlexRow>
      </div>
    </Box1>
  );
};

export const Button = styled.button<{ isStaked: boolean }>`
  color: #fff;
  background: ${(props) =>
    props.isStaked
      ? "transparent"
      : "linear-gradient(135deg, #0eace7 0%, #086eeb 100%)"};
  border: ${(props) => props.isStaked && "1.5px solid #0EACE7"};
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  border: 0;
  position: relative;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #1f1f30;
    transform: rotate(45deg);
    position: absolute;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    top: -12px;
    left: -13px;
  }
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    background: #1f1f30;
    transform: rotate(-132deg);
    position: absolute;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    top: auto;
    right: -13px;
    bottom: -12px;
  }
`;

const MyNFTs: React.FC<any> = () => {
  const [nftCollection, setNftCollection] = useState([]);
  const { apiData } = useApi();

  const { callODFactoryMethod } = useOverdriveContract();
  const { wallet } = useWalletProvider();
  useEffect(() => {
    if (wallet.account) {
      callODFactoryMethod(OD_FACTORY_METHODS.BALANCE_OF_BATCH, [
        wallet.account,
        ALL_CARDS,
      ]).then((result) => {
        if (!result) {
          return;
        }

        const { metadata } = apiData;
        const cards = result.map((card: any, index: number) => ({
          cardId: ALL_CARDS[index],
          amountOwned: formatHexToBN(card).toNumber(),
          metadata: {
            ...metadata[ALL_CARDS[index]].data,
          },
        }));
        setNftCollection(cards.filter((card: any) => card.amountOwned > 0));
      });
    }
  }, [wallet.account, apiData.metadata]);

  return (
    <FlexRow style={{ flex: 1, padding: "0 2rem 0 2rem" }}>
      <FlexColumn style={{ flex: 4, padding: "0 2rem 0 2rem" }}>
        {/*<FlexRow>*/}
        {/*  <FlexColumn*/}
        {/*    style={{*/}
        {/*      flex: 1,*/}
        {/*      paddingBottom: ".5rem",*/}
        {/*      borderBottom: "1px solid #3A486B",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Header2>My Collection</Header2>*/}
        {/*  </FlexColumn>*/}
        {/*</FlexRow>*/}
        {/*<Spacer size="sm" />*/}
        <FlexRow
          style={{
            flex: "1",
            flexWrap: "wrap",
            maxHeight: "90%",
            overflowY: "hidden",
            gap: ".5rem",
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-5">
                <h1>Coming soon</h1>
              </div>
            </div>
          </div>
          {/*{nftCollection.length*/}
          {/*  ? nftCollection.map((nft: any) => {*/}
          {/*      if (nft.metadata?.attributes) {*/}
          {/*        return <NFTView key={nft.metadata.name} nft={nft} />;*/}
          {/*      }*/}
          {/*    })*/}
          {/*  : "No NFTs found"}*/}
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
};

export default MyNFTs;
