import React, { useReducer } from "react";
import jwt from "jsonwebtoken";

const isValidCachedToken = () => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      return !!jwt.verify(
        localStorage.getItem("token"),
        process.env.REACT_APP_SECRET
      );
    }
  } catch (err) {
    localStorage.removeItem("token");

    return true;
  }
};
const initial = {
  jwt: isValidCachedToken() ? localStorage.getItem("token") : null,
  profile:
    localStorage.getItem("token") &&
    jwt.verify(localStorage.getItem("token"), process.env.REACT_APP_SECRET),
  existingAccount: !!localStorage.getItem("token"),
  loginFailed: false,
} as any;
export const OverdriveContext = React.createContext(null);

export const OverdriveProvider: React.FC = ({ children }) => {
  const overdriveReducer = (state: any, action: any) => {
    switch (action.type) {
      case "setJWT":
        // TODO move to httpOnly Cookie
        localStorage.setItem("token", action?.jwt?.token);
        return {
          jwt: action?.jwt?.token,
          profile: action?.jwt?.token
            ? jwt.verify(action?.jwt?.token, process.env.REACT_APP_SECRET)
            : null,
          existingAccount: action.existingAccount,
          loginFailed: action.loginFailed,
        };
      case "reset":
        return {
          ...initial,
        };
      case "logout":
        localStorage.removeItem("token");
        return {
          jwt: null,
          profile: null,
          existingAccount: false,
          loginFailed: false,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(overdriveReducer, initial);

  return (
    <OverdriveContext.Provider value={[state, dispatch]}>
      {children}
    </OverdriveContext.Provider>
  );
};
