import React from "react";

const Demo: React.FC<any> = () => {
  return (
    <div className="container-fluid">
      <div className="row mb-4 justify-content-between">
        <div className="col-auto">
          <h1>Demo</h1>
        </div>
        <div className="col-auto">
          <p>demo</p>
        </div>
      </div>
      {/* SimpleBar we add if need scrollbar */}
      {/*<SimpleBar forceVisible="y" style={{ width: "100%", maxHeight: "600px" }}>*/}
      <div className="row">
        <div className="col">Demo Content</div>
      </div>
      {/*</SimpleBar>*/}
    </div>
  );
};

export default Demo;
