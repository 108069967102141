import TopBar from "../../containers/TopBar/TopBar";
import SideBar from "../../containers/SideBar/SideBar";
import React from "react";
import "./Page.scss";

export const Page: React.FC<any> = ({ children }) => {
  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <TopBar />
          </div>
        </div>
      </header>
      <section className="content">
        <div className="container-fluid">
          <div className="row content-row">
            <div className="col max-width-118 d-none d-lg-block">
              <SideBar />
            </div>
            <div className="col">{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};
