import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const TopBarDefault: React.FC<any> = () => {
  const location = useLocation();
  const [hamburger, setHamburger] = useState("");
  const history = useHistory();
  const headerTitle = () => {
    switch (location.pathname) {
      case "/campaigns":
        return "Campaigns";
        break;
      case "/challenges":
        return "Challenges";
        break;
      case "/profile":
        return "Profile";
        break;
      default:
        return "Challenges";
    }
  };
  return (
    <>
      <div className="col max-width-118 d-flex justify-content-center align-items-center">
        <a href="/campaigns" className="d-flex">
          <svg
            width="56"
            height="35"
            viewBox="0 0 56 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 17.1066V9.86506C0 8.86142 0.389139 7.91758 1.09555 7.20823L7.17478 1.10057C7.87089 0.401276 8.8346 0 9.81944 0H22.0273L23.899 6.89386H11.1159L6.86185 11.1656V17.1066H0Z"
              fill="white"
            />
            <path
              d="M8.72511 24.0001L6.85352 17.1068H19.6496L23.9035 12.8351V6.90283H30.7652V14.1356C30.7652 15.1393 30.3763 16.0825 29.6703 16.7925L23.5907 22.9001C22.8945 23.5988 21.9305 24.0001 20.9461 24.0001H8.72511Z"
              fill="white"
            />
            <path
              d="M30.7656 6.89734V0.0356445H47.3025L49.1657 6.89734H30.7656Z"
              fill="white"
            />
            <path
              d="M30.7656 23.9973V17.1356H44.9312L49.1706 12.8784L49.1657 6.89404H56.0009V14.1727C56.0009 15.1718 55.6137 16.111 54.9106 16.8175L48.8541 22.9017C48.1611 23.5982 47.2014 23.9973 46.2214 23.9973H30.7656Z"
              fill="white"
            />
            <path
              d="M38.5601 34.0962C38.3735 34.0962 38.1708 34.0509 37.9521 33.9602C37.7388 33.8642 37.5388 33.7335 37.3521 33.5682H37.3281L37.2721 34.0002H36.7441V28.3042H37.4001V29.8562L37.3761 30.6082H37.4001C37.5761 30.4269 37.7788 30.2829 38.0081 30.1762C38.2428 30.0695 38.4748 30.0162 38.7041 30.0162C38.9655 30.0162 39.1975 30.0642 39.4001 30.1602C39.6028 30.2509 39.7708 30.3842 39.9041 30.5602C40.0428 30.7309 40.1468 30.9389 40.2161 31.1842C40.2855 31.4242 40.3201 31.6935 40.3201 31.9922C40.3201 32.3229 40.2721 32.6189 40.1761 32.8802C40.0801 33.1415 39.9495 33.3629 39.7841 33.5442C39.6241 33.7255 39.4375 33.8642 39.2241 33.9602C39.0108 34.0509 38.7895 34.0962 38.5601 34.0962ZM38.4561 33.5442C38.6268 33.5442 38.7841 33.5095 38.9281 33.4402C39.0721 33.3655 39.1975 33.2642 39.3041 33.1362C39.4108 33.0029 39.4935 32.8402 39.5521 32.6482C39.6108 32.4562 39.6401 32.2402 39.6401 32.0002C39.6401 31.7869 39.6188 31.5922 39.5761 31.4162C39.5335 31.2402 39.4668 31.0909 39.3761 30.9682C39.2908 30.8402 39.1788 30.7415 39.0401 30.6722C38.9015 30.6029 38.7361 30.5682 38.5441 30.5682C38.3735 30.5682 38.1895 30.6162 37.9921 30.7122C37.8001 30.8029 37.6028 30.9495 37.4001 31.1522V33.0882C37.5868 33.2535 37.7735 33.3709 37.9601 33.4402C38.1521 33.5095 38.3175 33.5442 38.4561 33.5442Z"
              fill="white"
            />
            <path
              d="M43.437 34.0962C43.1437 34.0962 42.869 34.0509 42.613 33.9602C42.3623 33.8695 42.141 33.7362 41.949 33.5602C41.7623 33.3842 41.613 33.1709 41.501 32.9202C41.3943 32.6642 41.341 32.3735 41.341 32.0482C41.341 31.7335 41.3943 31.4509 41.501 31.2002C41.613 30.9495 41.7597 30.7362 41.941 30.5602C42.1277 30.3842 42.3383 30.2509 42.573 30.1602C42.813 30.0642 43.0637 30.0162 43.325 30.0162C43.6023 30.0162 43.8503 30.0615 44.069 30.1522C44.293 30.2375 44.4797 30.3602 44.629 30.5202C44.7837 30.6802 44.901 30.8722 44.981 31.0962C45.061 31.3202 45.101 31.5682 45.101 31.8402C45.101 31.9149 45.0983 31.9869 45.093 32.0562C45.0877 32.1255 45.0797 32.1869 45.069 32.2402H42.005C42.0477 32.6829 42.2077 33.0162 42.485 33.2402C42.7677 33.4589 43.1117 33.5682 43.517 33.5682C43.7517 33.5682 43.9623 33.5362 44.149 33.4722C44.341 33.4029 44.525 33.3122 44.701 33.2002L44.941 33.6322C44.749 33.7602 44.5277 33.8695 44.277 33.9602C44.0317 34.0509 43.7517 34.0962 43.437 34.0962ZM43.341 30.5362C43.181 30.5362 43.0263 30.5629 42.877 30.6162C42.733 30.6695 42.6023 30.7469 42.485 30.8482C42.3677 30.9442 42.269 31.0669 42.189 31.2162C42.109 31.3655 42.053 31.5362 42.021 31.7282H44.501C44.4743 31.3282 44.357 31.0295 44.149 30.8322C43.9463 30.6349 43.677 30.5362 43.341 30.5362Z"
              fill="white"
            />
            <path
              d="M48.7459 34.0962C48.4632 34.0962 48.2259 34.0589 48.0339 33.9842C47.8419 33.9042 47.6872 33.7949 47.5699 33.6562C47.4526 33.5122 47.3672 33.3415 47.3139 33.1442C47.2659 32.9469 47.2419 32.7282 47.2419 32.4882V30.6482H46.1459V30.1522L47.2659 30.1122L47.3539 28.8642H47.8979V30.1122H49.8099V30.6482H47.8979V32.4962C47.8979 32.6722 47.9139 32.8269 47.9459 32.9602C47.9779 33.0882 48.0312 33.1975 48.1059 33.2882C48.1806 33.3789 48.2819 33.4482 48.4099 33.4962C48.5379 33.5389 48.6952 33.5602 48.8819 33.5602C49.0686 33.5602 49.2366 33.5442 49.3859 33.5122C49.5352 33.4802 49.6792 33.4375 49.8179 33.3842L49.9619 33.8642C49.7859 33.9282 49.5992 33.9815 49.4019 34.0242C49.2099 34.0722 48.9912 34.0962 48.7459 34.0962Z"
              fill="white"
            />
            <path
              d="M52.3588 34.0962C52.1774 34.0962 52.0068 34.0695 51.8468 34.0162C51.6868 33.9682 51.5454 33.8989 51.4228 33.8082C51.3054 33.7122 51.2121 33.5975 51.1428 33.4642C51.0734 33.3255 51.0388 33.1682 51.0388 32.9922C51.0388 32.7735 51.0894 32.5842 51.1908 32.4242C51.2974 32.2589 51.4628 32.1175 51.6868 32.0002C51.9161 31.8829 52.2094 31.7869 52.5668 31.7122C52.9241 31.6375 53.3534 31.5789 53.8548 31.5362C53.8494 31.4029 53.8281 31.2775 53.7908 31.1602C53.7534 31.0429 53.6948 30.9415 53.6148 30.8562C53.5348 30.7655 53.4281 30.6935 53.2948 30.6402C53.1668 30.5869 53.0094 30.5602 52.8228 30.5602C52.5668 30.5602 52.3214 30.6109 52.0868 30.7122C51.8521 30.8082 51.6441 30.9122 51.4628 31.0242L51.2068 30.5762C51.3028 30.5122 51.4148 30.4482 51.5428 30.3842C51.6761 30.3149 51.8148 30.2535 51.9588 30.2002C52.1081 30.1469 52.2654 30.1042 52.4308 30.0722C52.5961 30.0349 52.7641 30.0162 52.9348 30.0162C53.4681 30.0162 53.8628 30.1629 54.1188 30.4562C54.3801 30.7442 54.5108 31.1309 54.5108 31.6162V34.0002H53.9748L53.9188 33.4722H53.8948C53.6761 33.6429 53.4334 33.7895 53.1668 33.9122C52.9054 34.0349 52.6361 34.0962 52.3588 34.0962ZM52.5348 33.5682C52.7588 33.5682 52.9774 33.5202 53.1908 33.4242C53.4094 33.3229 53.6308 33.1789 53.8548 32.9922V31.9682C53.4388 32.0002 53.0894 32.0455 52.8068 32.1042C52.5294 32.1629 52.3054 32.2349 52.1348 32.3202C51.9694 32.4002 51.8521 32.4935 51.7828 32.6002C51.7134 32.7015 51.6788 32.8162 51.6788 32.9442C51.6788 33.0562 51.7028 33.1522 51.7508 33.2322C51.7988 33.3069 51.8601 33.3709 51.9348 33.4242C52.0148 33.4722 52.1054 33.5095 52.2068 33.5362C52.3134 33.5575 52.4228 33.5682 52.5348 33.5682Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <div className="col d-flex align-items-center justify-content-end justify-content-md-start page-title">
        <h1>{headerTitle()}</h1>
      </div>
      <div className="d-flex align-items-center">
        {location.pathname.indexOf("/challenge/") > -1 ? (
          <button
            className="button button-back"
            onClick={() => history.push(`/challenges`)}
          >
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L1 9L9 17" stroke="white" />
            </svg>
            Back
          </button>
        ) : null}
      </div>
    </>
  );
};

export default TopBarDefault;
