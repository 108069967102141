import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import TweetNowButton from "./components/TweetNowButton";
import useOverdriveApi from "../../hooks/useOverdriveApi";
import useModal from "../../hooks/useModal";
// import useScore from "../../hooks/useScore";
import TweetModal from "./components/TweetModal";
//import TweetCampaignBox from "./components/TweetCampaignBox";
import TweetBox from "../../components/TweetBox/TweetBox";
import "./campaign.scss";
import Dropdown from "./components/TweetDropDown";
import likeIcon from "../../assets/img/campaingIcons/like.svg";
import Loader from "../../components/Loader/Loader";
import TweetNotifyModal from "./components/TweetNotifyModal";
import TweetButton from "./components/TweetButton";
import ReactGA from "react-ga";
// import useAnimation from "../../hooks/useAnimation";

const FeedSelector: React.FC<any> = ({ activeCategory, setTweetCategory }) => {
  return (
    <div className="box feed-selector">
      <button
        style={{
          backgroundColor:
            activeCategory === "team" ? "#ffffff" : "transparent",
          color:
            activeCategory === "team" ? "#000000" : "rgba(255,255,255,0.3)",
        }}
        onClick={() => setTweetCategory("team")}
      >
        Team
      </button>
      <button
        style={{
          backgroundColor:
            activeCategory === "recent" ? "#ffffff" : "transparent",
          color:
            activeCategory === "recent" ? "#000000" : "rgba(255,255,255,0.3)",
        }}
        onClick={() => {
          setTweetCategory("recent");
        }}
      >
        Recent
      </button>
      <button
        style={{
          backgroundColor:
            activeCategory === "popular" ? "#ffffff" : "transparent",
          color:
            activeCategory === "popular" ? "#000000" : "rgba(255,255,255,0.3)",
        }}
        onClick={() => setTweetCategory("popular")}
      >
        Influential
      </button>
    </div>
  );
};

const Campaign: React.FC<any> = () => {
  const { slug }: any = useParams();
  const { apiData, get, post, formData } = useOverdriveApi();
  const [activeTweetIndex, setActiveTweetIndex] = useState(0);
  const [campaingnData, setCampaingnData] = useState(null);
  const [liked, setLiked] = useState(false);
  const [shared, setShared] = useState(false);
  const [quote, setQuote] = useState(false);
  const [commented, setCommented] = useState(false);
  const [tweetCategory, setTweetCategory] = useState<
    "team" | "recent" | "popular"
  >("recent");
  // const [dropdown, setDropdown] = useState(false);
  const [tweetID, setTweetID] = useState(null);
  const tweets = apiData["campaignTweets"];
  // const { updateScore } = useScore();
  // const { reputationAnimationPlay } = useAnimation();

  const campaignBySlug = apiData["campaignBySlug"];
  const [tweetNowActions, setTweetNowActions] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  ReactGA.initialize("UA-193287738-2");
  ReactGA.pageview(window.location.pathname + window.location.search);

  const handleCloseDropdown = () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdown);
    return () => {
      document.removeEventListener("click", handleCloseDropdown);
    };
  });

  const nextTweet = () => {
    // setActiveTweetIndex(activeTweetIndex + 1);
    setTimeout(() => handleSkip(tweets.data[activeTweetIndex].id), 2000);
  };

  const [handleNotifyModal] = useModal(<TweetNotifyModal />, "notify-modal");

  const showNotifyMessage = () => {
    handleNotifyModal();
    nextTweet();
  };

  const setTweetState = () => {
    if (!(tweets && tweets.data && activeTweetIndex < tweets.data.length)) {
      return;
    }
    const {
      interaction: { comment, like, share, quote },
    } = tweets.data[activeTweetIndex];

    setLiked(like || false);
    setCommented(comment || false);
    setShared(share || false);
    setQuote(quote || false);
  };

  const handleTweetComment = (data: any) => {
    if (!commented) {
      formData("commentTweet", data, [tweets.data[activeTweetIndex].id]).then(
        () => {
          setCommented(true);
          // updateScore(() => {
          // reputationAnimationPlay();
          // });
        }
      );
    }
  };
  const [handlePresentModal] = useModal(
    <TweetModal
      handleSend={handleTweetComment}
      campaingnData={campaingnData}
      comment={
        tweets?.data?.length &&
        tweets?.data[activeTweetIndex]?.data &&
        `@${tweets.data[activeTweetIndex].data.user.username}`
      }
    />,
    "comment-modal"
  );

  const handleQuote = () => {
    handleQuoteModal();
  };
  const handleTweetQuote = (data: any) => {
    if (!quote) {
      formData("quoteTweet", data, [tweets.data[activeTweetIndex].id]).then(
        () => {
          setQuote(true);
          //updateScore();
          // reputationAnimationPlay();
        }
      );
    }
  };
  const [handleQuoteModal] = useModal(
    <TweetModal
      handleSend={handleTweetQuote}
      campaingnData={campaingnData}
      comment={
        tweets?.data?.length &&
        tweets?.data[activeTweetIndex]?.data &&
        `@${tweets.data[activeTweetIndex].data.user.username}`
      }
    />,
    "quote-modal"
  );

  const handleSkip = (id: string) => {
    post("skipTweet", {}, [id]).then(() => {
      setActiveTweetIndex(activeTweetIndex + 1);
    });
  };

  const handleLike = (id: string) => {
    if (!liked) {
      post("likeTweet", {}, [id]).then((response) => {
        //console.log(response);
        if (response && response.status === 200) {
          setLiked(true);
          //updateScore();
          // reputationAnimationPlay();
        } else {
          showNotifyMessage();
        }
      });
    }
  };

  const handleShare = (id: string) => {
    if (!shared) {
      post("shareTweet", {}, [id]).then((responce) => {
        if (responce && responce.status === 200) {
          setShared(true);
          //updateScore();
          // reputationAnimationPlay();
        } else {
          showNotifyMessage();
        }
      });
    }
  };

  const handleDropdown = () => {
    if (!shared && !quote) {
      setShowDropdown(true);
    }
  };

  const handleComment = () => {
    if (!commented) {
      handlePresentModal();
    }
  };
  const getTweets = () => {
    return get("campaignTweets", [campaingnData.id, "tweet", tweetCategory]);
  };

  useEffect(() => {
    if (campaingnData && campaingnData.slug === slug) {
      getTweets();
    }
  }, [campaingnData, tweetCategory, slug]);

  // TODO alot of cleaning up / improving flow needed
  useEffect(() => {
    get("campaignBySlug", [slug]).catch((err) => console.error(err));
  }, [slug]);

  useEffect(() => {
    if (campaignBySlug && campaignBySlug.status === "completed") {
      setCampaingnData(campaignBySlug.data);
      setTweetNowActions([
        { id: campaignBySlug.data.id, keywords: campaignBySlug.data.tags },
      ]);
    }
  }, [campaignBySlug, setCampaingnData, setTweetNowActions]);

  useEffect(() => {
    if (!tweets || !tweets.data || !tweets.data.length) {
      setActiveTweetIndex(0);
      setLiked(false);
      setCommented(false);
      setShared(false);
      return;
    }

    if (activeTweetIndex >= tweets.data.length) {
      getTweets()
        .then(() => setActiveTweetIndex(0))
        .catch((err) => console.error(err));
    } else {
      setTweetState();
    }
  }, [activeTweetIndex, tweets]);

  useEffect(() => {
    if (liked && commented && (shared || quote)) {
      setTimeout(() => {
        setActiveTweetIndex(activeTweetIndex + 1);
      }, 2000);
    }
  }, [liked, commented, shared, quote]);

  return (
    <div className="container-fluid" style={{ position: "relative" }}>
      <div className="row justify-content-center campaign-content">
        <div className="col-md-2 d-flex flex-column justify-content-start tweet-action-wrap tweet-action-wrap-left">
          <TweetButton
            img={likeIcon}
            variant={"like"}
            activated={liked}
            handleClick={() => handleLike(tweets.data[activeTweetIndex].id)}
            disabled={!tweets?.data?.length}
          />
          <Dropdown
            tweetID={tweetID}
            handleShare={() => handleShare(tweets.data[activeTweetIndex].id)}
            handleQuote={() => handleQuote()}
            disabled={!tweets?.data?.length}
            activated={{ shared: shared, quote: quote }}
            isActiveDropdown={showDropdown}
          >
            <TweetButton
              variant={"quote"}
              activated={shared || quote}
              handleClick={() => handleDropdown()}
              disabled={!tweets?.data?.length}
            />
          </Dropdown>
        </div>
        <div className="col-md-5 d-flex flex-column tweet-content-wrap">
          <div className="d-flex justify-content-center align-content-center">
            <FeedSelector
              activeCategory={tweetCategory}
              setTweetCategory={setTweetCategory}
            />
          </div>
          {tweets && tweets.status === "completed" ? (
            <>
              <TweetBox
                tweet={
                  (tweets?.data?.length &&
                    tweets?.data[activeTweetIndex]?.data) ||
                  null
                }
              />
            </>
          ) : (
            <div className="tweet-box d-flex flex-column">
              <div className="tweet-text">
                <Loader />
              </div>
            </div>
          )}
          <div className="button-tweet-now-wrap d-flex align-content-center justify-content-center">
            {tweetNowActions && tweetNowActions.length && (
              <TweetNowButton
                tweetNowActions={tweetNowActions}
                campaingnData={campaingnData}
                campaignID={campaingnData.id}
                disabled={!tweets?.data?.length}
                // handleUpdateScore={updateScore}
              />
            )}
          </div>
        </div>
        <div className="col-md-2 d-flex flex-column justify-content-end tweet-action-wrap tweet-action-wrap-right">
          <TweetButton
            variant={"comment"}
            activated={commented}
            handleClick={() => handleComment()}
            disabled={!tweets?.data?.length}
          />
          <TweetButton
            variant={"skip"}
            handleClick={() => handleSkip(tweets.data[activeTweetIndex].id)}
            disabled={!tweets?.data?.length}
          />
        </div>
      </div>
    </div>
  );
};

export default Campaign;
