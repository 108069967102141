import React, {
  createContext,
  Component,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import Lottie from "lottie-web";

type ContextProps = {
  // reputationAnimationInit: () => void;
  reputationAnimationPlay: () => void;
  ReputationAnimationHTML: any;
};

export const AnimationContext = createContext<ContextProps>({
  // reputationAnimationInit: () => {},
  reputationAnimationPlay: () => {},
  ReputationAnimationHTML: null,
});

export class ReputationAnimationHTML extends Component<any, any> {
  render() {
    return <div id="scoreAnimation"></div>;
  }
}

export const AnimationProvider: React.FC<any> = ({ children }) => {
  const animItem = useRef(null);
  const reputationLottieAnimation = useRef(null);
  const IsReputationAnimationPlay = useRef(false);

  const reputationAnimationPlay = () => {
    if (!IsReputationAnimationPlay.current) {
      reputationAnimationInit();
      IsReputationAnimationPlay.current = true;
      animItem.current.goToAndPlay(0, true);
    }
  };

  const reputationAnimationInit = () => {
    reputationLottieAnimation.current = document.querySelector(
      "#scoreAnimation"
    );
    // @ts-ignore
    animItem.current = Lottie.loadAnimation({
      container: reputationLottieAnimation.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "https://assets3.lottiefiles.com/packages/lf20_di3slkpb.json",
    });
    reputationLottieAnimation.current.classList.remove("hidden");
    animItem.current.addEventListener("complete", () => {
      reputationLottieAnimation.current.classList.add("hidden");
      reputationLottieAnimation.current.innerHTML = " ";
      IsReputationAnimationPlay.current = false;
    });
  };

  return (
    <AnimationContext.Provider
      value={{
        reputationAnimationPlay: reputationAnimationPlay,
        ReputationAnimationHTML: ReputationAnimationHTML,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationProvider;
