import { useCallback, useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import useOverdrive from "./useOverdrive";
import useWalletProvider from "./useWalletProvider";
import { loadInitialContracts } from "../utils/contract";
import appConfig from "../config/config.prod";

// const INFURA_ID = "7a9c4ff3188d481f9143904079638424";
// const NETWORK_NAME = "mainnet";

function useWeb3Modal(config = {}) {
  const { wallet, dispatchWp } = useWalletProvider();
  const { overdrive } = useOverdrive();
  // const [autoLoaded, setAutoLoaded] = useState(false);
  // const {
  //   // autoLoad = true,
  //   infuraId = INFURA_ID,
  //   NETWORK = NETWORK_NAME,
  // }: any = config;

  // Web3Modal also supports many other wallets.
  // You can see other options at https://github.com/Web3Modal/web3modal
  const web3Modal = new Web3Modal({
    // network: "",
    // cacheProvider: true,
    providerOptions: {
      // walletconnect: {
      //   package: WalletConnectProvider,
      //   options: {
      //     infuraId,
      //   },
      // },
    },
    theme: "dark",
  });

  const loadContext = async (web3Provider: Web3Provider, x?: number) => {
    if (!web3Provider) {
      return;
    }

    const network = await web3Provider.getNetwork();
    const chainId = appConfig.supportedChains.includes(network.chainId)
      ? network.chainId
      : wallet.chainId || appConfig.defaultChain;
    const accounts = await web3Provider.listAccounts();
    const contracts = await loadInitialContracts(chainId, web3Provider);
    const jwt = overdrive.jwt;

    return dispatchWp({
      type: "setContext",
      contracts,
      chainId,
      account: jwt ? overdrive.profile.user.wallet.address : accounts[0],
      provider: web3Provider,
      web3AccountSelected: accounts[0],
      web3NetworkSelected: network.chainId,
    });
  };

  const resetApp = async () => {
    wallet.provider && wallet.provider.close && (await wallet.provider.close());

    await web3Modal.clearCachedProvider();
    await dispatchWp({
      type: "reset",
    });
    window.location.reload();
  };

  const subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }

    provider.removeAllListeners();

    provider.on("chainChanged", async (chainId: string) => {
      console.info("[PROVIDER] chain changed to ", chainId);

      dispatchWp({
        type: "web3NetworkChange",
        web3NetworkSelected: parseInt(chainId),
      });
      const web3Provider = new Web3Provider(provider, "any");
      console.log(web3Provider);
      loadContext(web3Provider, parseInt(chainId)).then(() => {
        console.info("Contracts LOADED");
      });
    });

    provider.on("accountsChanged", async (account: string) => {
      console.info("[PROVIDER] account changed to ", account);
      return dispatchWp({
        type: "web3AccountChange",
        web3AccountSelected: account[0],
      });
    });
  };

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(async () => {
    const newProvider = await web3Modal.connect();
    await subscribeProvider(newProvider);
    const web3Provider = new Web3Provider(newProvider, "any");

    loadContext(web3Provider).then(() => console.log("Contracts LOADED"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Modal]);

  const logoutOfWeb3Modal = useCallback(
    async function () {
      await resetApp();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3Modal]
  );

  // TODO -> Introduces problems when using multiple wallets and lots of network switching
  // If autoLoad is enabled and the the wallet had been loaded before, load it automatically now.
  // useEffect(() => {
  //   console.log(autoLoad && !autoLoaded && web3Modal.cachedProvider);
  //   if (autoLoad && !autoLoaded && web3Modal.cachedProvider) {
  //     loadWeb3Modal();
  //     setAutoLoaded(true);
  //   }
  // }, [
  //   autoLoad,
  //   autoLoaded,
  //   loadWeb3Modal,
  //   setAutoLoaded,
  //   web3Modal.cachedProvider,
  // ]);

  return [wallet.provider, loadWeb3Modal, logoutOfWeb3Modal];
}

export default useWeb3Modal;
