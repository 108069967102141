import React from "react";
import Modal from "../../../components/Modal";
//import useOverdrive from "../../../hooks/useOverdrive";

const TweetNotifyModal: React.FC<any> = ({ message, onDismiss }) => {
  //const { overdrive } = useOverdrive();
  return (
    <Modal style={{ background: "transparent" }}>
      <div className="box notify-modal p-3">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center">
              <h3 className="mb-1">
                {message ? <>{message}</> : <>Oops... Something went wrong.</>}
              </h3>
              <p className="mt-1 d-flex justify-content-center">
                You are moved to the next tweet.
              </p>
              <button
                className="button default"
                onClick={() => {
                  onDismiss();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TweetNotifyModal;
