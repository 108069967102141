import { useHistory } from "react-router-dom";
import React from "react";
import useWeb3Modal from "../../hooks/useWeb3Modal";
import useOverdrive from "../../hooks/useOverdrive";
import { auth } from "../../services/firebase";

const LogoutButton: React.FC<any> = ({ variant = "primary" }) => {
  const history = useHistory();
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const getAuth = auth.getAuth();
  const { dispatchOd } = useOverdrive();
  const logoutOverdrive = () => {
    logoutOfWeb3Modal();
    getAuth.signOut();
    dispatchOd({
      type: "logout",
    });
    history.push("/");
  };

  return (
    <button className="button default logout" onClick={() => logoutOverdrive()}>
      LOGOUT
    </button>
  );
};

export default LogoutButton;
