import React from "react";
import ErrorMessage from "./ErrorMessage";
import ExpiredChallenge from "./ExpiredChallenge";

const CodeChallenge: React.FC<any> = ({
  submitChallenge,
  handleChange,
  errorMessage,
  challengeBySlug,
}) => {
  return (
    <>
      {challengeBySlug.data.active ? (
        <div className="d-flex flex-column action-wrap">
          {!challengeBySlug.data.completed ? (
            <>
              <label htmlFor="">SUBMIT YOUr CODE</label>
              <input
                type="text"
                name="formValue"
                id="formValue"
                placeholder="Paste the code"
                onChange={handleChange}
              />
              <button
                onClick={() => submitChallenge()}
                className="challenge-submit d-flex justify-content-center align-content-center"
              >
                SUBMIT
              </button>
              <ErrorMessage errorMessage={errorMessage} />
            </>
          ) : (
            <>
              <div className="d-flex flex-column action-wrap">
                <h4 className="mb-0">YOUr CODE</h4>
                <p>{challengeBySlug.data.submission.work}</p>
              </div>
            </>
          )}
        </div>
      ) : (
        <ExpiredChallenge />
      )}
    </>
  );
};

export default CodeChallenge;
