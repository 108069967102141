import React, { useReducer } from "react";

const initial = {
  account: null,
  chainId: null,
  contracts: new Map([]),
  provider: null, //getDefaultProvider(), //new JsonRpcProvider("https://bsc-dataseed1.binance.org:443"),
  web3AccountSelected: null,
  web3NetworkSelected: null,
} as any;
export const WalletContext = React.createContext(null);

export const WalletProvider: React.FC = ({ children }) => {
  const providerReducer = (state: any, action: any) => {
    switch (action.type) {
      case "setContext":
        const newState = {
          provider: action.provider,
          account: action.account,
          chainId: action.chainId,
          contracts: action.contracts,
          web3AccountSelected: action.web3AccountSelected,
          web3NetworkSelected: action.web3NetworkSelected,
        };
        // @ts-ignore
        window.overdrive = newState;
        return newState;
      case "resetContext":
        const initialState = {
          ...initial,
        };
        // @ts-ignore
        window.overdrive = initialState;
        return initialState;
      case "web3AccountChange":
        return {
          ...state,
          web3AccountSelected: action.web3AccountSelected,
        };
      case "web3NetworkChange":
        return {
          ...state,
          web3NetworkSelected: action.web3NetworkSelected,
        };
      case "appNetworkChange":
        return {
          ...state,
          chainId: action.chainId,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(providerReducer, initial);

  return (
    <WalletContext.Provider value={[state, dispatch]}>
      {children}
    </WalletContext.Provider>
  );
};
