import { isAddress } from "@ethersproject/address";

export const isValidAddress = (address: string): boolean => {
  return isAddress(address);
};

export const isSameAddress = (address1: string, address2: string): boolean => {
  if (!address1 || !address2) {
    return false;
  }
  return address1.toLowerCase() === address2.toLowerCase();
};

export const formatAddress = (address: string) => {
  return `${address.substr(0, 5)}..${address.substr(-3, 3)}`;
};

export const getChainId = async (provider: any) => {
  const { chainId } = await provider.getNetwork();
  return parseInt(chainId);
};
