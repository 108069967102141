import React from "react";

const ExpiredChallenge: React.FC<any> = () => {
  return (
    <div className="error-message">
      <h2>this challenge expired</h2>
    </div>
  );
};

export default ExpiredChallenge;
