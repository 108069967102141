import React, { useEffect } from "react";
import useTwitterAuthentication from "../../hooks/useTwitterAuthentication";
import { Body, Header2, Header3, Overlay } from "../../components";
import Modal from "../../components/Modal";
import ModalContent from "../../components/ModalContent";
import ModalActions from "../../components/ModalActions";
import TwitterLogin from "../../components/TwitterLogin/TwitterLogin";
import useFirebase from "../../hooks/useFirebase";
import WalletButton from "../../components/WalletButton/WalletButton";
import useWeb3Modal from "../../hooks/useWeb3Modal";
import useWalletProvider from "../../hooks/useWalletProvider";
import useOverdrive from "../../hooks/useOverdrive";
import useOverdriveApi from "../../hooks/useOverdriveApi";
// import ModalImage from "../../components/ModalImage";
import LoginStepper from "../../components/LoginStepper";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
// import useScore from "../../hooks/useScore";

export interface OverdriveAuth {
  profile: {
    signupAt: string;
    federatedId: string;
    email: string;
    emailVerified: boolean;
    fullName: string;
    displayName: string;
    screenName: string;
    photoUrl: string;
    oauthAccessToken: string;
    oauthTokenSecret: string;
    refreshToken: string;
    followers: string;
    friends: string;
    location: string;
    bannerUrl: string;
  };
  wallet: {
    address: string;
  };
  signupCode: string;
}

export const createAuthBody = (firebase: any, wallet: any): OverdriveAuth => ({
  profile: {
    signupAt: firebase.additionalUserInfo.profile.created_at,
    federatedId: firebase.user.providerData[0].uid,
    email: firebase.user.providerData[0].email,
    emailVerified: firebase.user.emailVerified,
    fullName: firebase.additionalUserInfo.profile.name,
    displayName: firebase.user.providerData[0].displayName,
    screenName: firebase.additionalUserInfo.profile.screen_name,
    photoUrl: firebase.user.providerData[0].photoURL,
    oauthAccessToken: firebase.credential.accessToken,
    oauthTokenSecret: firebase.credential.secret,
    refreshToken: firebase.user.refreshToken,
    followers: firebase.additionalUserInfo.profile.followers_count,
    friends: firebase.additionalUserInfo.profile.friends_count,
    location: firebase.additionalUserInfo.profile.location,
    bannerUrl: firebase.additionalUserInfo.profile.profile_banner_url,
  },
  wallet: {
    address: wallet.account,
  },
  signupCode: new URLSearchParams(document.location.search).get("r"),
});

const LoginModal: React.FC<any> = ({
  isTwitterAuthenticated,
  firebase,
  wallet,
  overdrive,
}) => {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  return (
    <Modal
      style={{
        padding: "40px 0",
        minWidth: "40rem",
        background: "transparent",
      }}
    >
      {!isTwitterAuthenticated ? (
        <div className="">
          {/*<ModalImage />*/}
          <ModalContent>
            <h1 style={{ fontSize: "64px", marginBottom: "0" }}>
              POST-TO-EARN
            </h1>
            <p
              style={{
                fontSize: "24px",
                marginTop: "16px",
                marginBottom: "100px",
              }}
            >
              Create, Engage and Earn $DRIVE and NFTs.
            </p>
          </ModalContent>
          <ModalActions>
            <TwitterLogin />
          </ModalActions>
          <p
            style={{
              margin: "auto",
              marginBottom: "140px",
              maxWidth: "360px",
              textAlign: "center",
              display: "block",
            }}
          >
            Your account must be at least 30 days old and have more than 20
            followers.
          </p>
          <LoginStepper currentStep={isTwitterAuthenticated ? 2 : 1} />
        </div>
      ) : wallet.account ? (
        <>
          <ModalContent>
            <Header2 style={{ padding: "1.5rem 0 1rem 0" }}>
              {overdrive.loginFailed ? "Login failed" : "Logging in..."}
            </Header2>
            <Header3>
              {overdrive.loginFailed
                ? "Twitter account does not have 20 followers."
                : "Fetching account..."}
            </Header3>
          </ModalContent>
          <ModalActions>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {overdrive.loginFailed && (
              <button
                className="twitter-login mt-2"
                onClick={logoutOfWeb3Modal}
              >
                Restart
              </button>
            )}
          </ModalActions>
        </>
      ) : (
        <>
          {/*<ModalImage />*/}
          <ModalContent>
            <h1 style={{ fontSize: "64px", marginBottom: "0" }}>
              hello {firebase.additionalUserInfo.profile.name}!
            </h1>
            <p
              style={{
                fontSize: "24px",
                marginTop: "16px",
                marginBottom: "100px",
              }}
            >
              Connect your wallet and Post-to-Earn.
            </p>
          </ModalContent>
          <ModalActions>
            <WalletButton
              wallet={wallet}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
            />
          </ModalActions>
          <p
            style={{
              margin: "auto",
              marginBottom: "140px",
              maxWidth: "360px",
              textAlign: "center",
              display: "block",
            }}
          >
            <a href="https://metamask.io/faqs.html">
              learn more about Metamask wallet
            </a>
          </p>
          <LoginStepper currentStep={isTwitterAuthenticated ? 2 : 1} />
        </>
      )}
    </Modal>
  );
};

export const Login = () => {
  const isTwitterAuthenticated = useTwitterAuthentication();
  const { firebase } = useFirebase();
  const { wallet } = useWalletProvider();
  const { overdrive } = useOverdrive();
  const { apiData, post } = useOverdriveApi();
  const history = useHistory();
  ReactGA.initialize("UA-193287738-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  // const { setupScore } = useScore();

  useEffect(() => {
    // TODO improve check
    //console.log(firebase);
    if (
      isTwitterAuthenticated &&
      wallet.account &&
      firebase.credential &&
      !overdrive.jwt &&
      !overdrive.loginFailed &&
      apiData["auth"]?.status !== "pending"
    ) {
      const body = createAuthBody(firebase, wallet);
      post("auth", body).catch((err) => console.error(err));
    }
  }, [firebase, wallet, overdrive]);

  useEffect(() => {
    if (overdrive.jwt) {
      history.push("/campaigns");
      //setupScore();
    }
  }, [overdrive.jwt]);

  return (
    <Body>
      <Overlay>
        <svg
          className="loginLogo"
          width="56"
          height="24"
          viewBox="0 0 56 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 17.1066V9.86506C0 8.86142 0.389139 7.91758 1.09555 7.20823L7.17478 1.10057C7.87089 0.401276 8.8346 0 9.81944 0H22.0273L23.899 6.89386H11.1159L6.86185 11.1656V17.1066H0Z"
            fill="white"
          />
          <path
            d="M8.72511 24.0001L6.85352 17.1068H19.6496L23.9035 12.8351V6.90283H30.7652V14.1356C30.7652 15.1393 30.3763 16.0825 29.6703 16.7925L23.5907 22.9001C22.8945 23.5988 21.9305 24.0001 20.9461 24.0001H8.72511Z"
            fill="white"
          />
          <path
            d="M30.7656 6.89734V0.0356445H47.3025L49.1657 6.89734H30.7656Z"
            fill="white"
          />
          <path
            d="M30.7656 23.9973V17.1356H44.9312L49.1706 12.8784L49.1657 6.89404H56.0009V14.1727C56.0009 15.1718 55.6137 16.111 54.9106 16.8175L48.8541 22.9017C48.1611 23.5982 47.2014 23.9973 46.2214 23.9973H30.7656Z"
            fill="white"
          />
        </svg>
        <LoginModal
          isTwitterAuthenticated={isTwitterAuthenticated}
          firebase={firebase}
          wallet={wallet}
          overdrive={overdrive}
        />
      </Overlay>
    </Body>
  );
};
