import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import useOverdriveApi from "../hooks/useOverdriveApi";

type ContextProps = {
  scoreReputation?: number;
  scoreMaxReputation?: number;
  scoreMinReputation?: number;
  scoreInfluence?: number;
  scoreLevel?: number;
  scoreRank?: string;
  updateScore: any;
  setupScore: () => void;
};

export const ScoreContext = createContext<ContextProps>({
  scoreReputation: 0,
  scoreMaxReputation: 0,
  scoreMinReputation: 0,
  scoreInfluence: 0,
  scoreLevel: 0,
  scoreRank: "Pleb",
  updateScore: () => {},
  setupScore: () => {},
});

export const ScoreProvider: React.FC<any> = ({ children }) => {
  const [reputation, setReputation] = useState(0);
  const [maxReputation, setMaxReputation] = useState(0);
  const [minReputation, setMinReputation] = useState(0);
  const [influence, setInfluence] = useState(0);
  const [level, setLevel] = useState(0);
  const [rank, setRank] = useState(null);
  const levelAll = useRef(null);
  const { get } = useOverdriveApi();
  const MaxPossibleScore = 50000;

  const handleScore = useCallback(
    (callback: any) => {
      get("userMe")
        .then((responce) => {
          if (levelAll.current[responce.data.level]) {
            if (responce.data.reputation >= minReputation) {
              setMinReputation(maxReputation);
            }
            setMaxReputation(
              levelAll.current[responce.data.level].minReputation - 1
            );
          } else {
            if (responce.data.reputation >= minReputation) {
              setMinReputation(maxReputation);
            }
            setMaxReputation(MaxPossibleScore);
          }
          setLevel(responce.data.level);
          setRank(levelAll.current[responce.data.level - 1].rank);
          setReputation(responce.data.reputation);
          setInfluence(responce.data.influence);
          if (callback) callback();
        })
        .catch((err) => console.error(err));
    },
    [levelAll]
  );

  const InitialScoreCall = useCallback(() => {
    get("levelAll")
      .then((responce) => {
        const responceCopy = responce.data.data.slice();
        responceCopy.sort(function (a: any, b: any) {
          return a.level - b.level;
        });
        levelAll.current = responceCopy;
      })
      .then(() => {
        handleScore(null);
      })
      .catch((err) => console.error(err));
  }, [levelAll]);

  useEffect(() => {
    // InitialScoreCall();
  }, [InitialScoreCall]);

  return (
    <ScoreContext.Provider
      value={{
        scoreReputation: reputation,
        scoreInfluence: influence,
        scoreLevel: level,
        scoreRank: rank,
        scoreMaxReputation: maxReputation,
        scoreMinReputation: minReputation,
        updateScore: handleScore,
        setupScore: InitialScoreCall,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};

export default ScoreProvider;
