import { OverdriveContract } from "../utils/contract";
import { Contract } from "@ethersproject/contracts";
import useWalletProvider from "./useWalletProvider";
import config from "../config/config.prod";

export enum OD_FACTORY_METHODS {
  URI = "uri",
  BALANCE_OF_BATCH = "balanceOfBatch",
  IS_APPROVED_FOR_ALL = "isApprovedForAll",
  SET_APPROVED_FOR_ALL = "setApprovedForAll",
}
export enum OD_STORE_METHODS {
  REDEEM = "redeem",
  CARD_COSTS = "cardCosts",
}
export enum OD_BOOST_STAKE_METHODS {
  STAKE = "stakeCard",
  UNSTAKE = "unstakeCard",
  GET_STAKED_CARDS = "getStakedCardsForAddress",
}

const ODFactoryCall: { [key in OD_FACTORY_METHODS]: any } = {
  [OD_FACTORY_METHODS.URI]: async (contract: Contract, id: string) =>
    contract.uri(id),
  [OD_FACTORY_METHODS.BALANCE_OF_BATCH]: async (
    contract: Contract,
    address: string,
    ids: string[]
  ) => {
    const addresses = Array(ids.length).fill(address);
    return contract.balanceOfBatch(addresses, ids);
  },
  [OD_FACTORY_METHODS.IS_APPROVED_FOR_ALL]: async (
    contract: Contract,
    address: string,
    approvedAddress: string
  ) => contract.isApprovedForAll(address, approvedAddress),
  [OD_FACTORY_METHODS.SET_APPROVED_FOR_ALL]: async (
    contract: Contract,
    approveAddress: string
  ) => contract.setApprovalForAll(approveAddress, true),
} as any;

const ODStoreCall: { [key in OD_STORE_METHODS]: any } = {
  [OD_STORE_METHODS.REDEEM]: async (contract: Contract, id: string) =>
    contract.redeem(id),
  [OD_STORE_METHODS.CARD_COSTS]: async (contract: Contract, id: string) =>
    contract.cardCosts(id),
};

const ODBoostStakeCall: { [key in OD_BOOST_STAKE_METHODS]: any } = {
  [OD_BOOST_STAKE_METHODS.GET_STAKED_CARDS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getStakedCardsForAddress(address);
  },
  [OD_BOOST_STAKE_METHODS.STAKE]: async (
    contract: Contract,
    cardId: string
  ) => {
    return contract.stakeCard(cardId);
  },
  [OD_BOOST_STAKE_METHODS.UNSTAKE]: async (
    contract: Contract,
    cardId: string
  ) => {
    return contract.unstakeCard(cardId);
  },
};

const useOverdriveContract = () => {
  const { wallet } = useWalletProvider();

  const callODContractMethod = async (
    contract: OverdriveContract,
    method: OD_FACTORY_METHODS | OD_STORE_METHODS | OD_BOOST_STAKE_METHODS,
    args: any
  ) => {
    // TODO not supported chain
    if (
      !config.supportedChains.includes(parseInt(wallet.web3NetworkSelected)) ||
      !wallet.provider ||
      !contract
    ) {
      return;
    }

    if (
      !wallet.contracts ||
      !wallet.contracts.has(contract) ||
      !wallet.contracts.get(contract).address
    ) {
      console.error(
        `[useContractCall] Missing contracts or contract not (yet) loaded`
      );
      return;
    }

    if (contract === OverdriveContract.OD_FACTORY) {
      if (!ODFactoryCall[method as OD_FACTORY_METHODS]) {
        console.error(`[ODFactoryCall] method: ${method} not found`);
      }

      return ODFactoryCall[method as OD_FACTORY_METHODS](
        wallet.contracts.get(contract),
        ...args
      );
    }

    if (contract === OverdriveContract.OD_STORE) {
      if (!ODStoreCall[method as OD_STORE_METHODS]) {
        console.error(`[ODStoreCall] method: ${method} not found`);
      }

      return ODStoreCall[method as OD_STORE_METHODS](
        wallet.contracts.get(contract),
        ...args
      );
    }

    if (contract === OverdriveContract.OD_BOOST_STAKE) {
      if (!ODBoostStakeCall[method as OD_BOOST_STAKE_METHODS]) {
        console.error(`[ODBoostStakeCall] method: ${method} not found`);
      }

      return ODBoostStakeCall[method as OD_BOOST_STAKE_METHODS](
        wallet.contracts.get(contract),
        ...args
      );
    }
  };

  return {
    callODFactoryMethod: (method: OD_FACTORY_METHODS, args: any[]) =>
      callODContractMethod(OverdriveContract.OD_FACTORY, method, args),
    callODStoreMethod: (method: OD_STORE_METHODS, args: any[]) =>
      callODContractMethod(OverdriveContract.OD_STORE, method, args),
    callODBoostStakeMethod: (method: OD_BOOST_STAKE_METHODS, args: any[]) =>
      callODContractMethod(OverdriveContract.OD_BOOST_STAKE, method, args),
  };
};

export default useOverdriveContract;
