import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useOverdriveApi from "../../hooks/useOverdriveApi";
import Loader from "../../components/Loader/Loader";
import SubmitChallenge from "./components/SubmitChallenge";
import SocialChallenge from "./components/SocialChallenge";
import CodeChallenge from "./components/CodeChallenge";
import WorkChallenge from "./components/WorkChallenge";
import DetailsChallenge from "./components/DetailsChallenge";
import ReferralChallenge from "./components/ReferralChallenge";
import "./challenge.scss";
import ReactGA from "react-ga";

const Challenge: React.FC<any> = () => {
  const { slug }: any = useParams();
  const { apiData, get, post } = useOverdriveApi();
  const challengeBySlug = apiData["challengeBySlug"];
  const userMe = apiData["userMe"];
  const [ifSubmit, setIfSubmit] = useState(false);
  const [formValue, setFormValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  ReactGA.initialize("UA-193287738-2");
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    get("challengeBySlug", [slug]).catch((err) => console.error(err));
  }, [slug]);

  useEffect(() => {
    if (
      challengeBySlug &&
      challengeBySlug.status === "completed" &&
      challengeBySlug.data.type === "referral"
    ) {
      get("userMe", []).catch((err) => console.error(err));
    }
  }, [challengeBySlug]);

  const submitChallenge = () => {
    if (challengeBySlug.data.type === "work") {
      post("challengeSubmitWork", { work: formValue }, [
        challengeBySlug.data.id,
      ]).then((response) => {
        if (response) {
          setIfSubmit(true);
        } else {
          setErrorMessage("Not a valid work URL");
        }
      });
    }
    if (challengeBySlug.data.type === "code") {
      post("challengeSubmitCode", { code: formValue }, [
        challengeBySlug.data.id,
      ]).then((response) => {
        if (response) {
          setIfSubmit(true);
        } else {
          setErrorMessage("Not a valid code");
        }
      });
    }
    if (
      challengeBySlug.data.type === "like" ||
      challengeBySlug.data.type === "follow" ||
      challengeBySlug.data.type === "retweet"
    ) {
      post("challengeSubmitSocial", { action: challengeBySlug.data.type }, [
        challengeBySlug.data.id,
      ]).then((response) => {
        if (response) {
          setIfSubmit(true);
        }
      });
    }
  };
  const handleChange = (e: any) => {
    setFormValue(e.target.value);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        {ifSubmit ? (
          <div className="col-12 d-flex justify-content-center">
            <SubmitChallenge />
          </div>
        ) : (
          <>
            {challengeBySlug && challengeBySlug.status === "completed" ? (
              <>
                <div className="col-md-6 col-12 d-flex">
                  <DetailsChallenge challengeBySlug={challengeBySlug} />
                </div>
                <div className="col-md-6 col-12 d-flex align-items-center justify-content-center">
                  {challengeBySlug.data.type === "work" ? (
                    <WorkChallenge
                      challengeBySlug={challengeBySlug}
                      submitChallenge={submitChallenge}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  ) : null}
                  {challengeBySlug.data.type === "code" ? (
                    <CodeChallenge
                      submitChallenge={submitChallenge}
                      challengeBySlug={challengeBySlug}
                      handleChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  ) : null}
                  {userMe &&
                  userMe?.status === "completed" &&
                  challengeBySlug.data.type === "referral" ? (
                    <ReferralChallenge
                      challengeBySlug={challengeBySlug}
                      userMe={userMe}
                    />
                  ) : null}
                  {challengeBySlug.data.type === "like" ||
                  challengeBySlug.data.type === "follow" ||
                  challengeBySlug.data.type === "retweet" ? (
                    <SocialChallenge
                      submitChallenge={submitChallenge}
                      challengeBySlug={challengeBySlug}
                      errorMessage={errorMessage}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div className="col-12">
                <Loader />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Challenge;
