import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Modal from "../../../components/Modal";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import TweetFileUpload from "./TweetFileUpload";
import useOverdrive from "../../../hooks/useOverdrive";

const TweetModal: React.FC<any> = ({
  onDismiss,
  comment,
  handleSend,
  campaingnData,
}) => {
  const { overdrive } = useOverdrive();
  const [textInput, setTextInput] = useState(comment ? comment + " " : "");
  const [emojiInput, setEmojiInputOpen] = useState(false);
  const [noOfCharacters, setNoOfCharacters] = useState(0);
  const [tags] = useState(campaingnData.tags);
  const [containsTags, setContainsTags] = useState(tags?.length ? null : true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const emojiInputRef = useRef(null);

  useOutsideClick(emojiInputRef, () => {
    if (emojiInput) {
      setEmojiInputOpen(!emojiInput);
    }
  });

  const includesOneOFTheTags = (inputString: string) => {
    let tagFound = false;
    //console.log(inputString);
    tags.forEach((tag: string) => {
      const isFound = inputString.includes(tag);
      if (isFound) {
        tagFound = true;
      }
    });
    return tagFound;
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    setTextInput(textInput + emojiObject.emoji);
  };

  useEffect(() => {
    setNoOfCharacters(textInput.length);
    if (textInput.length) {
      if (tags) setContainsTags(includesOneOFTheTags(textInput));
      setNoOfCharacters(textInput.length);
    }
  }, [includesOneOFTheTags, tags, textInput]);
  return (
    <Modal style={{ background: "transparent" }}>
      <div className="box modal-tweet">
        <div className="modal-content">
          <div className="user-image">
            <img
              src={overdrive.profile.user.profile.photoUrl}
              style={{
                flex: 1,
                height: "48px",
                objectFit: "contain",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="user-tweet">
            <a className="tweet-modal-close" onClick={() => onDismiss()}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L16.9998 17"
                  stroke="white"
                  strokeMiterlimit="10"
                />
                <path
                  d="M16.9998 1L1 17"
                  stroke="white"
                  strokeMiterlimit="10"
                />
              </svg>
            </a>
            <textarea
              style={{
                color: noOfCharacters < 40 ? "red" : "white",
                width: "100%",
              }}
              cols={30}
              rows={6}
              maxLength={280}
              placeholder="What’s happening?"
              value={textInput}
              onChange={(event) => setTextInput(event.target.value)}
            />
            <div className="mobile-tags">
              <div className="modal-tags">
                {tags &&
                  tags.length &&
                  tags.map((value: any, index: any) => {
                    const addTag = (value: any, event: any) => {
                      if (!textInput.includes(value)) {
                        setTextInput(textInput + " " + value);
                      }
                    };
                    const tag = (value: any, index: any) => {
                      let classname = "";
                      if (textInput.includes(value)) {
                        classname = "disable";
                      }
                      return (
                        <div
                          key={index}
                          className={`tag-item ${classname}`}
                          onClick={(event) => addTag(value, event)}
                        >
                          {value}
                        </div>
                      );
                    };
                    return tag(value, index);
                  })}
              </div>
            </div>
            <div className="tweet-control">
              <div className="control-item control-item-emoji">
                <a onClick={() => setEmojiInputOpen(!emojiInput)}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.75 21.5C4.822 21.5 0 16.678 0 10.75C0 4.822 4.822 0 10.75 0C16.678 0 21.5 4.822 21.5 10.75C21.5 16.678 16.678 21.5 10.75 21.5ZM10.75 1.5C5.65 1.5 1.5 5.65 1.5 10.75C1.5 15.85 5.65 20 10.75 20C15.85 20 20 15.85 20 10.75C20 5.65 15.85 1.5 10.75 1.5Z"
                      fill="white"
                    />
                    <path
                      d="M10.7498 15.8653C8.85776 15.8653 7.11676 14.9153 6.09376 13.3213C5.86976 12.9733 5.97076 12.5113 6.31976 12.2863C6.66776 12.0603 7.13176 12.1623 7.35576 12.5123C8.10276 13.6743 9.37176 14.3673 10.7508 14.3673C12.1298 14.3673 13.3988 13.6743 14.1468 12.5133C14.3708 12.1633 14.8348 12.0633 15.1828 12.2883C15.5328 12.5123 15.6328 12.9763 15.4088 13.3243C14.3838 14.9183 12.6428 15.8693 10.7508 15.8693L10.7498 15.8653Z"
                      fill="white"
                    />
                    <path
                      d="M13.4878 9.68647C14.304 9.68647 14.9658 9.02475 14.9658 8.20847C14.9658 7.39219 14.304 6.73047 13.4878 6.73047C12.6715 6.73047 12.0098 7.39219 12.0098 8.20847C12.0098 9.02475 12.6715 9.68647 13.4878 9.68647Z"
                      fill="white"
                    />
                    <path
                      d="M8.0112 9.68647C8.82748 9.68647 9.4892 9.02475 9.4892 8.20847C9.4892 7.39219 8.82748 6.73047 8.0112 6.73047C7.19493 6.73047 6.5332 7.39219 6.5332 8.20847C6.5332 9.02475 7.19493 9.68647 8.0112 9.68647Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div className="control-item control-item-emoji-popup">
                {emojiInput && (
                  <div ref={emojiInputRef}>
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                      skinTone={SKIN_TONE_NEUTRAL}
                      pickerStyle={{
                        position: "absolute",
                        top: "2.2rem",
                        left: "-2rem",
                        height: "250px",
                        width: "290px",
                        boxShadow: "none",
                        zIndex: "999",
                        borderRadius: "0",
                      }}
                      native
                    />
                  </div>
                )}
              </div>
              <div className="control-item control-item-upload">
                <TweetFileUpload getUploadedFile={setUploadedFile} />
              </div>
              <div className="control-item control-item-characters">
                {noOfCharacters} / 180
              </div>
              <div className="control-item control-item-button">
                <button
                  disabled={!containsTags || noOfCharacters < 40}
                  onClick={() => {
                    const data = new FormData();
                    data.append("status", textInput);
                    if (uploadedFile)
                      data.append("embed", uploadedFile, uploadedFile.name);
                    handleSend(data);
                    onDismiss();
                  }}
                  className="tweet-button"
                >
                  Tweet
                </button>
              </div>
            </div>
            <div className="tweet-message">
              {tags && tags.length && (
                <>
                  <p>
                    Must include at least one of the tags: {tags.join(" , ")}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="modal-tags">
          {tags &&
            tags.length &&
            tags.map((value: any, index: any) => {
              const addTag = (value: any, event: any) => {
                if (!textInput.includes(value)) {
                  setTextInput(textInput + " " + value);
                }
              };
              const tag = (value: any, index: any) => {
                let classname = "";
                if (textInput.includes(value)) {
                  classname = "disable";
                }
                return (
                  <div
                    key={index}
                    className={`tag-item ${classname}`}
                    onClick={(event) => addTag(value, event)}
                  >
                    {value}
                  </div>
                );
              };
              return tag(value, index);
            })}
        </div>
      </div>
    </Modal>
  );
};

export default TweetModal;
