import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useApi from "../../hooks/useMetadataApi";
import useOverdriveContract, {
  OD_STORE_METHODS,
} from "../../hooks/useOverdriveContract";
import { formatHexToBN, weiToUnit } from "../../utils/conversions";
import useWalletProvider from "../../hooks/useWalletProvider";
import { send } from "../../utils/contract";
import useTransaction from "../../hooks/useTransaction";
import "./Product.scss";
import image1 from "../Marketplace/1.jpg";
import image2 from "../Marketplace/2.jpg";
import image3 from "../Marketplace/3.jpg";
import useERC20Contract from "../../hooks/useERC20Contract";
// @ts-ignore
import { addresses } from "@overdrive/contracts";
import { BigNumber } from "@ethersproject/bignumber";
import config from "../../config/config.prod";

const products = [
  {
    cardId: 1,
    cardCost: {
      type: "BigNumber",
      hex: "0x0de0b6b3a7640000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/1",
      image: image1,
      name: "Pepemander",
      description: "Pepemon Gen 1 Starters - Pepemander [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 2,
    cardCost: {
      type: "BigNumber",
      hex: "0x0f43fc2c04ee0000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/2",
      image: image2,
      name: "Pepertle",
      description: "Pepemon Gen 1 Starters - Pepertle [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 3,
    cardCost: {
      type: "BigNumber",
      hex: "0x1f399b1438a10000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/3",
      image: image3,
      name: "Pepesaur",
      description: "Pepemon Gen 1 Starters - Pepesaur [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 1,
    cardCost: {
      type: "BigNumber",
      hex: "0x0de0b6b3a7640000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/1",
      image: image1,
      name: "Pepemander",
      description: "Pepemon Gen 1 Starters - Pepemander [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 2,
    cardCost: {
      type: "BigNumber",
      hex: "0x0f43fc2c04ee0000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/2",
      image: image2,
      name: "Pepertle",
      description: "Pepemon Gen 1 Starters - Pepertle [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 3,
    cardCost: {
      type: "BigNumber",
      hex: "0x1f399b1438a10000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/3",
      image: image3,
      name: "Pepesaur",
      description: "Pepemon Gen 1 Starters - Pepesaur [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
];

const Product: React.FC<any> = () => {
  const { id }: any = useParams();
  const { apiData } = useApi();
  const { callODStoreMethod } = useOverdriveContract();
  const { dispatchTx } = useTransaction();

  const [product, setProduct] = useState(null);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  // const getRarity = (metadata: any) =>
  //   metadata &&
  //   metadata.attributes &&
  //   metadata.attributes.find((attr: any) => attr.trait_type === "Rarity").value;

  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const [driveBalance, setDriveBalance] = useState(BigNumber.from(0));
  const { getAllowance, approve, balanceOf } = useERC20Contract();
  const { wallet } = useWalletProvider();

  useEffect(() => {
    if (
      wallet.account &&
      config.supportedChains.includes(parseInt(wallet.chainId)) &&
      parseInt(wallet.chainId) === parseInt(wallet.web3NetworkSelected)
    ) {
      getAllowance(
        addresses[wallet.chainId].tokens.drive,
        addresses[wallet.chainId].overdriveStore
      ).then((allowance) => {
        if (!allowance) {
          return;
        }
        setAllowance(BigNumber.from(allowance));
      });

      balanceOf(addresses[wallet.chainId].tokens.drive, wallet.account).then(
        (balance) => {
          if (!balance) {
            return;
          }
          setDriveBalance(BigNumber.from(balance));
        }
      );
    }
  }, [wallet.account, wallet.web3NetworkSelected, isApproving]);

  useEffect(() => {
    if (wallet.account) {
      callODStoreMethod(OD_STORE_METHODS.CARD_COSTS, [id]).then((result) => {
        const { metadata } = apiData;
        const card = {
          cardId: id,
          cardCost: result ? formatHexToBN(result) : BigNumber.from(0),
          metadata: {
            ...metadata[id].data,
          },
        };

        setProduct(card);
      });
    }
  }, [wallet.account, apiData.metadata]);

  const handleRedeemCard = async () => {
    setIsRedeeming(true);

    send(
      wallet.provider,
      () => callODStoreMethod(OD_STORE_METHODS.REDEEM, [id]),
      dispatchTx
    )
      .then((result) => {
        setIsRedeeming(false);
        if (result) {
          setIsRedeemed(false);
        }
      })
      .catch((err) => {
        setIsRedeeming(false);
        console.log(err);
      });
  };

  const handleApprove = async () => {
    setIsApproving(true);

    approve(
      addresses[wallet.chainId].tokens.drive,
      addresses[wallet.chainId].overdriveStore
    )
      .then((result) => {
        if (result) {
          setIsApproving(false);
        }
      })
      .catch((err) => {
        setIsApproving(false);
        console.log(err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-5">
          <div className="product-image">
            <img src={product?.metadata.image} alt={product?.name} />
          </div>
        </div>
        <div className="col-5">
          <div className="product-details d-flex flex-column">
            <div className="title-wrap">
              <p className="mt-0 mb-0" style={{ color: "#6CDB38" }}>
                Level-up
              </p>
              <h1 className="mt-3 mb-1">{product?.metadata.name}</h1>
              <h2 className="mt-0 mb-0">Fedos.art</h2>
            </div>
            <div className="description-wrap">
              <p>Common – Open Edition</p>
              <p>Level 1 Level-up</p>
              <p>+1.1x Reputation</p>
            </div>
            <div className="button-wrap">
              <p>
                {product?.cardCost &&
                  weiToUnit(BigNumber.from(product.cardCost))}{" "}
                Drive
              </p>
              {allowance.gt(BigNumber.from(product?.cardCost || 0)) ? (
                <button
                  className="button-buy"
                  disabled={
                    BigNumber.from(product?.cardCost || 0).gt(driveBalance) ||
                    isRedeemed ||
                    isRedeeming
                  }
                  onClick={() => handleRedeemCard()}
                >
                  {isRedeeming ? "Buying..." : isRedeemed ? "Owned" : "Buy Now"}
                </button>
              ) : (
                <button
                  className="button-buy"
                  disabled={isApproving}
                  onClick={() => handleApprove()}
                >
                  {isApproving ? "Approving..." : "Approve"}
                </button>
              )}
              {BigNumber.from(product?.cardCost || 0).gt(driveBalance) && (
                <p>Insufficient funds</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
