import { BigNumber } from "@ethersproject/bignumber";

export const formatHexToBN = (value: string) => {
  return BigNumber.from(value);
};

export const weiToUnit = (value: BigNumber, decimals = 18) => {
  const result = value.mul(10000).div(BigNumber.from(10).pow(decimals));
  return result.toNumber() / 10000;
};
