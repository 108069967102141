import React, { useEffect, useState } from "react";
import ButtonSwitch from "./ButtonSwitch";
import RankingsTable from "./RankingsTable";
import "./Rankings.scss";
import useOverdriveApi from "../../hooks/useOverdriveApi";
import Loader from "../../components/Loader/Loader";

const Rankings: React.FC<any> = () => {
  const [rankingCategory, setRankingCategory] = useState("all-time");
  const { apiData, get } = useOverdriveApi();
  const userLeaderboard = apiData["userLeaderboard"];

  useEffect(() => {
    get("userLeaderboard").catch((err) => console.error(err));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-around">
        <div className="col-6 d-flex">
          <div className="dropdown-wrap">Global</div>
        </div>
        <div className="col-6 d-flex justify-content-end">
          {/*<ButtonSwitch*/}
          {/*  rankingCategory={rankingCategory}*/}
          {/*  setRankingCategory={setRankingCategory}*/}
          {/*/>*/}
        </div>
      </div>
      <div className="row border-bottom mt-5">
        <div className="col-12">
          {userLeaderboard && userLeaderboard.data ? (
            <>{userLeaderboard.data.data.length} participants</>
          ) : null}
        </div>
      </div>
      <div className="row border-bottom mt-2">
        <div className="col-12">
          <div className="row">
            {userLeaderboard && userLeaderboard.data ? (
              <RankingsTable RankingsData={userLeaderboard.data.data} />
            ) : userLeaderboard && userLeaderboard.status === "pending" ? (
              <Loader />
            ) : (
              <div className="col">
                {userLeaderboard && userLeaderboard.error.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rankings;
