import { MaxUint256 } from "@ethersproject/constants";
import useWalletProvider from "./useWalletProvider";
import useTransaction from "./useTransaction";
import { send } from "../utils/contract";
import { loadERC20Contract } from "../utils/contract";

const useERC20Contract = () => {
  const { wallet } = useWalletProvider();
  const { dispatchTx } = useTransaction();

  const balanceOf = async (contractAddress: string, address: string) => {
    const contract = await loadERC20Contract(contractAddress, wallet.provider);
    if (!contract || !contract.address) {
      console.info("[balanceOf] failed to load contract");
      return;
    }
    return contract.balanceOf(address);
  };

  const approve = async (
    contractAddress: string,
    approveAddress: string,
    amount?: string
  ) => {
    if (parseFloat(amount) <= 0) {
      console.error("[sendTransation] amount <= 0");
      return;
    }

    const contract = await loadERC20Contract(contractAddress, wallet.provider);

    return send(
      wallet.provider,
      () => contract.approve(approveAddress, amount || MaxUint256),
      dispatchTx
    );
  };

  const allowance = async (
    contractAddress: string,
    approvedAddress: string
  ) => {
    const contract = await loadERC20Contract(contractAddress, wallet.provider);
    if (!contract || !contract.address) {
      console.info("[allowance] failed to load contract");
      return;
    }

    return contract.allowance(wallet.account, approvedAddress);
  };

  return {
    balanceOf: async (contractAddress: string, address: string) =>
      await balanceOf(contractAddress, address),
    approve: async (
      contractAddress: string,
      approveAddress: string,
      amount?: string
    ) => approve(contractAddress, approveAddress, amount),
    getAllowance: async (contractAddress: string, approveAddress: string) =>
      await allowance(contractAddress, approveAddress),
  };
};

export default useERC20Contract;
