import styled from "styled-components";
import loginBgImg from "../assets/img/login_bg.svg";

interface FlexProps {
  justifyContent?: string | boolean;
  alignItems?: string | boolean;
}

export const Body = styled.div`
  //font-family: Helvetica;
  //font-size: calc(10px + 2vmin);
  //color: white;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
`;

// export const Body = styled.div`
//   font-family: Helvetica;
//   background-color: #181821;
//   color: white;
//   font-size: calc(10px + 2vmin);
//   justify-content: center;
//   height: calc(100vh - ${(props) => props.theme.topBarSize}px);
//   margin-right: 2rem;
// `;

export const Top = styled.header`
  display: grid;
  height: 10rem;
  grid-template-columns: 170px auto auto 220px;
  .grid-column-start-1 {
    grid-column-start: 1;
    align-self: center;
    place-self: center;
    margin-right: 40px;
  }
  .grid-column-start-2 {
    grid-column-start: 2;
    align-self: center;
  }
  .grid-column-start-3 {
    grid-column-start: 3;
    align-self: center;
  }
  .grid-column-start-4 {
    grid-column-start: 4;
    align-self: center;
    justify-self: end;
  }
`;

export const Header1 = styled.div`
  font-family: Helvetica;
  color: ${(props) => props.theme.color.white};
  font-size: 36px;
  font-weight: 700;
`;

export const Header2 = styled.div`
  font-family: Helvetica;
  color: ${(props) => props.theme.color.white};
  font-size: 28px;
  font-weight: 700;
`;

export const Header3 = styled.div`
  font-family: Helvetica;
  color: ${(props) => props.theme.color.grey[300]};
  font-size: 20px;
`;

export const Paragraph1 = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  text-align: center;
  color: #ffffff;
  opacity: 0.7;
`;

export const Typo1 = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #ffffff;
  opacity: 0.6;
`;

export const Typo2 = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  text-align: center;
  color: #ffffff;
  opacity: 1;
`;

export const FlexRow = styled.div<any>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

export const FlexColumn = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

export const Overlay = styled.div`
  //font-family: Helvetica;
  align-items: center;
  justify-content: center;
  //background: url(${loginBgImg}) no-repeat center center fixed;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //position: relative;
`;

export const Box1 = styled.div`
  border: 1px solid #3b486d;
  background-color: ${(props) => props.theme.color.dark[3]};
  *:last-child {
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 500;
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  border-radius: 8px;
  color: ${(props) => (!props.disabled ? "#282c34" : "#6c726c")};
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  margin: 0px 20px;
  padding: 12px 24px;

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;

export const ButtonWithCutoff = styled.button<any>`
  color: ${(props) => (!props.disabled ? props.theme.color.white : "#6c726c")};
  background: ${(props) =>
    props.background
      ? props.background
      : "linear-gradient(135deg, #0eace7 0%, #086eeb 100%)"};
  border: ${(props) => props.border && props.border};
  cursor: pointer;
  width: 420px;
  height: 60px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 18px solid
      ${(props) => (props.cutOffColor ? props.cutOffColor : "#222e40")};
    border-left: 18px solid transparent;
    width: 0;
  }
`;

export const Button2 = styled.div<any>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
  display: inline-block;
  height: ${(props) => props.height}rem;
  position: relative;
  width: ${(props) => props.width}rem;
  cursor: pointer;
`;

export const ButtonWithCutoff2 = styled.div<any>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
  display: inline-block;
  height: ${(props) => props.height}rem;
  position: relative;
  width: ${(props) => props.width}rem;
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  opacity: ${(props) => (!props.disabled ? 1 : 0.5)};

  &:before {
    border-bottom: ${(props) =>
        props.height * (props.heightPercentage / 100)}rem
      solid
      ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.white};
    border-left: ${(props) => props.width * (props.widthPercentage / 100)}rem
      solid
      ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.white};
    border-right: ${(props) => props.height * (props.heightPercentage / 100)}rem
      solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -${(props) => props.height * (props.heightPercentage / 100)}rem;
    width: 0;
  }
`;

export const BoxWithCutoffTopRight = styled.div<any>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
  display: inline-block;
  height: 35px;
  position: relative;
  width: 200px;
  &:before {
    border-bottom: 15px solid
      ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.white};
    border-left: 175px solid
      ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.white};
    border-right: 20px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -15px;
    width: 0;
  }
`;
