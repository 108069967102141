import React from "react";
import Modal from "../Modal";
import ModalContent from "../ModalContent";
import ModalTitle from "../ModalTitle";
import { Button, Typo1 } from "../index";
import ModalActions from "../ModalActions";

const InfoModal: React.FC<any> = ({
  message,
  actionButton,
  actionButtonText,
  actionButton2,
  actionButtonText2,
  closeButton,
  onDismiss,
}) => {
  return (
    <Modal
      style={{
        maxWidth: "510px",
        backgroundColor: "#000000",
        textAlign: "center",
      }}
    >
      <h3>Warning</h3>
      <p>{message}</p>
      <div className="d-flex justify-content-center">
        {actionButton && (
          <button
            className="button default m-2"
            onClick={() => {
              actionButton();
              onDismiss();
            }}
          >
            {actionButtonText}
          </button>
        )}
        {actionButton2 && (
          <button
            className="button default m-2"
            onClick={() => {
              actionButton2();
              onDismiss();
            }}
          >
            {actionButtonText2}
          </button>
        )}
        {closeButton && <Button onClick={() => onDismiss()}>CLOSE</Button>}
      </div>
    </Modal>
  );
};

export default InfoModal;
