import React, { useEffect, useState } from "react";
import retweet from "../../../assets/img/retweet.svg";
import quoteTweet from "../../../assets/img/quote-tweet.svg";

const Dropdown: React.FC<any> = ({
  children,
  handleShare,
  handleQuote,
  disabled,
  activated,
  tweetID,
  isActiveDropdown,
}) => {
  const [isActive, setIsActive] = useState("");
  const [isActiveShared, setIsActiveShared] = useState(false);
  const [isActiveQuote, setIsActiveQuote] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    // if (activated.quote === true && activated.shared === true) {
    //   setIsActive("disabled");
    // }
    setShowMenu(isActiveDropdown);
    setIsActiveShared(disabled || activated.shared);
    setIsActiveQuote(disabled || activated.quote);
  }, [
    disabled,
    activated.shared,
    activated.quote,
    isActiveDropdown,
    setShowMenu,
  ]);
  return (
    <>
      {showMenu ? (
        <div className={`dropdown ${isActive}`}>
          {children}
          <ul className="dropdown-options box">
            <li key={`${tweetID}-handleShare`}>
              <button
                onClick={() => {
                  handleShare();
                }}
                disabled={isActiveShared}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M26 17.3491L21.9478 21.395L17.8669 17.3309L19.2995 15.8923L20.9179 17.5041V7.67213C20.9197 7.40414 20.8165 7.15182 20.6275 6.96159C20.4367 6.76938 20.1818 6.66365 19.91 6.66365H8.98035L6.94176 4.63339H19.91C20.7267 4.63339 21.493 4.95216 22.0678 5.53059C22.6399 6.10664 22.9523 6.87035 22.948 7.68185V17.5275L24.5654 15.9124L26 17.3491ZM16.6543 19.3524H6.08998C5.81802 19.3524 5.56332 19.2467 5.3725 19.0547C5.18346 18.8644 5.08031 18.6119 5.08209 18.3439V8.51201L6.70055 10.1237L8.13313 8.68518L4.05219 4.62109L0 8.66713L1.43457 10.1037L3.05203 8.4888V18.3342C3.04767 19.1457 3.36009 19.9096 3.93217 20.4855C4.50703 21.0639 5.27332 21.3827 6.08998 21.3827H19.0582L17.0197 19.3524H16.6543Z"
                      fill="white"
                    />
                  </g>
                </svg>
                Retweet
              </button>
            </li>
            <li key={`${tweetID}-handleQuote`}>
              <button
                onClick={() => {
                  handleQuote();
                }}
                disabled={isActiveQuote}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.7543 0.532698L19.4839 3.25995C20.1716 3.94709 20.1729 5.14248 19.4825 5.8323L7.14443 18.0499C7.0898 18.104 7.02268 18.1431 6.94896 18.1644L0.579933 19.9826C0.421328 20.0279 0.250278 19.9834 0.133432 19.8667C0.0467987 19.7801 0.000137314 19.6643 0.000137309 19.5453C0.000137307 19.5036 0.00593374 19.4614 0.0174827 19.4206L1.8372 13.057C1.8585 12.9833 1.89762 12.9163 1.95183 12.8617L14.1811 0.532698C14.525 0.189108 14.9817 3.26443e-05 15.4677 3.2623e-05C15.9537 3.26018e-05 16.4105 0.189108 16.7543 0.532698ZM16.1761 7.81305L14.5029 6.13988L5.76733 14.8754L7.02161 15.5024C7.17566 15.5796 7.27286 15.7367 7.27286 15.909L7.27286 16.6369L16.1761 7.81305ZM1.11656 18.8835L3.18915 18.2914L1.70868 16.8109L1.11656 18.8835ZM2.61557 13.6363L1.99431 15.8111L4.18902 18.0058L6.36379 17.3845L6.36379 16.19L4.79686 15.4066C4.70895 15.3626 4.63749 15.2912 4.59355 15.2033L3.81011 13.6363L2.61557 13.6363L2.61557 13.6363ZM3.36314 12.7272L4.09106 12.7272C4.2633 12.7272 4.42041 12.8244 4.49767 12.9784L5.1246 14.2327L13.8601 5.49715L12.187 3.82398L3.36314 12.7272ZM14.8132 1.17411L12.8269 3.17835L16.8217 7.17321L18.8246 5.1882C19.1682 4.84461 19.1682 4.24628 18.8246 3.90269L16.0973 1.17543C15.9256 1.00366 15.6974 0.909102 15.4546 0.909102C15.2118 0.909102 14.9836 1.00366 14.8132 1.17411Z"
                    fill="white"
                  />
                </svg>
                Quote Tweet
              </button>
            </li>
          </ul>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Dropdown;
