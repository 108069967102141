import React, { useEffect } from "react";
import { auth } from "../../services/firebase";
import useFirebase from "../../hooks/useFirebase";
// import { useHistory } from "react-router-dom";
import { ButtonWithCutoff, Button } from "../index";

import twitterBird from "../../assets/img/twitter_bird.svg";

const buttonList = {
  twitter: {
    visible: true,
    provider: () => auth.twitterOAuth(),
  },
};

const SocialButtonList: React.FC<any> = ({
  buttonList,
  auth,
  logoutText,
  currentProviders = null,
}) => {
  const { firebase, dispatchFb } = useFirebase();
  // const history = useHistory();

  const authHandler = (authData: any) => {
    if (authData) {
      dispatchFb({
        type: "login",
        user: authData.user,
        credential: authData.credential,
        additionalUserInfo: authData.additionalUserInfo,
      });
      //
      // if (currentProviders === null) {
      //   // history.push("/");
      //
      // }
      if (currentProviders !== null) {
        currentProviders(authData.user.providerData);
      }
    } else {
      console.error("Error authenticating");
    }
  };

  const authenticate = (e: any, provider: any) => {
    const providerOAuth = buttonList[provider].provider();

    if (!auth().currentUser) {
      auth()
        .signInWithPopup(providerOAuth)
        .then(authHandler)
        .catch((err: any) => console.error(err));
    } else {
      auth().signOut();
      dispatchFb({ type: "logout" });
    }
  };

  const renderButtonList = (provider: any) => {
    return (
      <div key={provider}>
        {!auth().currentUser || !firebase.user ? (
          <button
            key={"twitterLogin"}
            onClick={(e: any) => authenticate(e, provider)}
            className="button twitter-login"
          >
            <img src={twitterBird} style={{ paddingRight: ".5rem" }} />
            {"Continue with Twitter"}
          </button>
        ) : (
          <Button
            key={"twitterLogout"}
            onClick={(e) => authenticate(e, provider)}
          >
            {logoutText || "Twitter logout"}
          </Button>
        )}
      </div>
    );
  };

  return <div>{Object.keys(buttonList).map(renderButtonList)}</div>;
};

const TwitterLogin: React.FC<any> = ({ logout }) => {
  const { firebase } = useFirebase();
  const getAuth = auth.getAuth();

  useEffect(() => {
    if (
      firebase.user &&
      (!firebase.credential || !firebase.additionalUserInfo)
    ) {
      getAuth.signOut();
    }
  }, [
    getAuth.currentUser,
    firebase.user,
    firebase.credential,
    firebase.additionalUserInfo,
  ]);

  return (
    <SocialButtonList
      buttonList={buttonList}
      auth={(auth as any).getAuth}
      logoutText={logout}
    />
  );
};

export default TwitterLogin;
