import React, { useState } from "react";
import { Box1, ButtonWithCutoff, Header2, Header3 } from "../../components";
import subtractIcon from "../../assets/img/icons/Subtract.svg";
import commentIcon from "./comment.svg";
import rocketIcon from "./rocket.svg";
import { Link } from "react-router-dom";
import { Tabs } from "../../components/Tabs/Tabs";

const BalanceTile: React.FC = () => {
  return (
    <Box1 style={{ width: "500px", height: "300px", padding: "40px" }}>
      <Header3 style={{ fontWeight: "bold" }}>$drive Balance</Header3>
      <p
        style={{
          fontSize: "48px",
          fontWeight: "bold",
          fontFamily: "monospace",
          marginTop: "10px",
          marginBottom: "70px",
        }}
      >
        <i>
          <img
            style={{ height: "40px" }}
            src={subtractIcon}
            alt="sdrive subtractIcon"
          />
        </i>{" "}
        <span style={{ marginLeft: "-10px" }}>2321</span>
      </p>
      <span>
        +32{" "}
        <span style={{ fontSize: "18px", color: "#c4b9b7" }}>last 24hrs</span>
      </span>
    </Box1>
  );
};

const LinkTile: React.FC = () => {
  return (
    <Box1 style={{ width: "500px", height: "300px", padding: "40px" }}>
      <Header3 style={{ fontWeight: "bold", marginBottom: "1rem" }}>
        Current Rank
      </Header3>
      <ButtonWithCutoff
        style={{
          padding: "20px",
          height: "100px",
          width: "100px",
          cursor: "unset",
          marginBottom: "1rem",
        }}
      >
        <span style={{ fontSize: "56px" }}>4</span>th
      </ButtonWithCutoff>
      <Link
        style={{
          fontSize: "18px",
          textDecoration: "none",
          color: "rgb(8, 110, 235)",
        }}
        to={"/dashboard"}
      >
        Leaderboard
      </Link>
    </Box1>
  );
};

const activities = [
  { description: "Received 25xp from comment", timestamp: "5 sec ago" },
  { description: "1k likes in the last 24h achieved", timestamp: "5 sec ago" },
  { description: "Received 25xp from comment", timestamp: "5 sec ago" },
  { description: "1k likes in the last 24h achieved", timestamp: "5 sec ago" },
];

const TagHolder: React.FC<any> = ({ tag }: { tag: string }) => {
  return (
    <div
      style={{
        backgroundColor: "#181821",
        padding: "auto",
        paddingBottom: "1rem",
      }}
    >
      <p style={{ fontSize: "16px", textAlign: "center", color: "#3590FF" }}>
        {tag}
      </p>
    </div>
  );
};

const ActivityTile: React.FC<any> = () => {
  return (
    <Box1 style={{ width: "500px", padding: "40px" }}>
      <Tabs items={["Your Activity", "Others"]} />
      <div style={{ padding: "1rem" }}>
        {activities.map((activity) => (
          <div
            style={{
              padding: "0.5rem",
              backgroundColor:
                activities.indexOf(activity) % 2 == 1 ? "#181821" : "",
            }}
          >
            <i>
              <img
                src={rocketIcon}
                width={20}
                style={{ marginRight: "1rem" }}
              />
            </i>
            <span style={{ fontSize: "16px", color: "#c4b9b7" }}>
              {activity.description}
            </span>
            <span
              style={{
                float: "right",
                fontSize: "10px",
                color: "#c4b9b7",
                marginTop: "1.25rem",
              }}
            >
              5 sec ago
            </span>
          </div>
        ))}
        <hr
          style={{ backgroundColor: "#3b486d", border: "none", height: "2px" }}
        />
        <Header3>Keywords</Header3>
        <div
          style={{
            margin: "1rem",
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            gridTemplateRows: "auto auto auto",
            gridGap: "20px",
          }}
        >
          <TagHolder tag={"#working"} />
          <TagHolder tag={"#working"} />
          <TagHolder tag={"#working"} />
          <TagHolder tag={"#working"} />
          <TagHolder tag={"#working"} />
          <TagHolder tag={"#working"} />
        </div>
      </div>
    </Box1>
  );
};

const Marketplace: React.FC<any> = () => {
  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <Header2>Statistics</Header2>
      </div>
      <div className="row mb-4">
        <div className="col-auto" style={{ marginBottom: "1rem" }}>
          <BalanceTile />
        </div>
        <div className="col-auto" style={{ marginBottom: "1rem" }}>
          <LinkTile />
        </div>
        <div className="col-auto" style={{ marginBottom: "1rem" }}>
          <ActivityTile />
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
