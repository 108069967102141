import React, { useState } from "react";
import useModal from "../../../hooks/useModal";
import TweetModal from "./TweetModal";
import useOverdriveApi from "../../../hooks/useOverdriveApi";

const TweetNowButton: React.FC<any> = ({
  tweetNowActions,
  campaignID,
  campaingnData,
  disabled,
  // handleUpdateScore,
}) => {
  const { formData } = useOverdriveApi();
  const [tweetNowIndex, setTweetNowIndex] = useState(0);
  const handleNewTweet = (data: any) => {
    formData("newTweet", data, [campaignID])
      .then((respond) => {
        if (tweetNowIndex + 1 < tweetNowActions?.length) {
          setTweetNowIndex(tweetNowIndex + 1);
        }
        // handleUpdateScore();
      })
      .catch(console.error);
  };
  const [handlePresent] = useModal(
    <TweetModal
      campaingnData={campaingnData}
      handleSend={handleNewTweet}
      keywords={tweetNowActions[tweetNowIndex].keywords}
    />,
    "tweet-modal"
  );
  return (
    <button
      className="button button-tweet-now"
      disabled={disabled}
      onClick={handlePresent}
    >
      Tweet
      {/*{tweetNowActions.length} Reward Available  */}
    </button>
  );
};

export default TweetNowButton;
