import React, { useEffect } from "react";
import { Page } from "../components/Page/Page";
import useOverdrive from "../hooks/useOverdrive";
import { useHistory } from "react-router-dom";

function withPage(WrappedComponent: React.FC) {
  return function WithPageComponent({ ...props }) {
    const { overdrive } = useOverdrive();
    const history = useHistory();
    useEffect(() => {
      if (!overdrive.jwt) {
        history.push("/");
      }
    }, [overdrive]);
    return (
      <Page>
        <WrappedComponent {...props} />
      </Page>
    );
  };
}

export default withPage;
