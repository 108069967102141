import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";

const SideBar: React.FC<any> = () => {
  const history = useHistory();
  const [activePath, setActivePath] = useState(history.location.pathname);
  return (
    <div className="box menu">
      <StyledNavLink
        onClick={() => setActivePath("/campaigns")}
        exact
        activeClassName="active"
        to="/campaigns"
        className=""
      >
        <ImageContainer active={activePath === "/campaigns"}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="10" height="6" stroke="#8E8F91" />
            <rect
              x="21"
              y="21"
              width="10"
              height="6"
              transform="rotate(-180 21 21)"
              stroke="#8E8F91"
            />
            <rect x="1" y="7" width="10" height="14" stroke="#8E8F91" />
            <rect
              x="21"
              y="15"
              width="10"
              height="14"
              transform="rotate(-180 21 15)"
              stroke="#8E8F91"
            />
          </svg>
          <span>Campaigns</span>
        </ImageContainer>
      </StyledNavLink>
      <StyledNavLink
        onClick={() => setActivePath("/challenges")}
        exact
        activeClassName="active"
        to="/challenges"
      >
        <ImageContainer active={activePath === "/challenges"}>
          <svg
            width="25"
            height="22"
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H23.6667M1 21L12.4286 1.57143L23.6667 21H1Z"
              stroke="#4B4C4E"
            />
          </svg>
          <span>Challenges</span>
        </ImageContainer>
      </StyledNavLink>
      {/*<StyledNavLink*/}
      {/*  onClick={() => setActivePath("/my-nfts")}*/}
      {/*  exact*/}
      {/*  activeClassName="active"*/}
      {/*  to="/my-nfts"*/}
      {/*>*/}
      {/*  <ImageContainer active={activePath === "/my-nfts"}>*/}
      {/*    <svg*/}
      {/*      width="22"*/}
      {/*      height="22"*/}
      {/*      viewBox="0 0 22 22"*/}
      {/*      fill="none"*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*    >*/}
      {/*      <rect x="1" y="1" width="13" height="16" stroke="#4B4C4E" />*/}
      {/*      <rect x="8" y="5" width="13" height="16" stroke="#4B4C4E" />*/}
      {/*    </svg>*/}
      {/*    <span>My NFTs</span>*/}
      {/*  </ImageContainer>*/}
      {/*</StyledNavLink>*/}
      {/*<StyledNavLink*/}
      {/*  onClick={() => setActivePath("/marketplace")}*/}
      {/*  exact*/}
      {/*  activeClassName="active"*/}
      {/*  to="/marketplace"*/}
      {/*>*/}
      {/*  <ImageContainer active={activePath === "/marketplace"}>*/}
      {/*    <svg*/}
      {/*      width="22"*/}
      {/*      height="22"*/}
      {/*      viewBox="0 0 22 22"*/}
      {/*      fill="none"*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*    >*/}
      {/*      <path d="M1.25781 1.22607L20.8657 20.8339" stroke="#4B4C4E" />*/}
      {/*      <path d="M20.8657 1.22607L1.25781 20.8339" stroke="#4B4C4E" />*/}
      {/*      <path d="M21 1H1V21H21V1Z" stroke="#4B4C4E" />*/}
      {/*    </svg>*/}
      {/*    <span>NFT Store</span>*/}
      {/*  </ImageContainer>*/}
      {/*</StyledNavLink>*/}
      {/*<StyledNavLink*/}
      {/*  onClick={() => setActivePath("/rankings")}*/}
      {/*  exact*/}
      {/*  activeClassName="active"*/}
      {/*  to="/rankings"*/}
      {/*>*/}
      {/*  <ImageContainer active={activePath === "/diagram"}>*/}
      {/*    <svg*/}
      {/*      width="22"*/}
      {/*      height="22"*/}
      {/*      viewBox="0 0 22 22"*/}
      {/*      fill="none"*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*    >*/}
      {/*      <path d="M20.9004 1.09961L1 21" stroke="#8E8F91" />*/}
      {/*      <path d="M1.51758 1.0001H21.0008V20.4834" stroke="#8E8F91" />*/}
      {/*    </svg>*/}
      {/*    <span>Rankings</span>*/}
      {/*  </ImageContainer>*/}
      {/*</StyledNavLink>*/}
      <StyledNavLink
        onClick={() => setActivePath("/profile")}
        exact
        activeClassName="active"
        to="/profile"
      >
        <ImageContainer active={activePath === "/profile"}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="11" cy="11" r="10" stroke="#8E8F91" />
          </svg>
          <span>Profile</span>
        </ImageContainer>
      </StyledNavLink>
    </div>
  );
};

interface ImageContainerProps {
  active: boolean;
}

const StyledNavLink = styled(NavLink)`
  margin-bottom: 5px;
  text-decoration: none;
`;

const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  padding: 15px 0 13px 0;
  box-sizing: border-box;
  // border: 1.5px solid #3a486b;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: #4b4c4e;
    margin-top: 8px;
    line-height: 10px;
  }
`;
export default SideBar;

//active = linear-gradient(135deg, #0EACE7 0%, #086EEB 100%);
