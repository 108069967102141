import { auth } from "../services/firebase";
import { useEffect, useState } from "react";
import useFirebase from "./useFirebase";

const useTwitterAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { firebase } = useFirebase();
  // const history = useHistory();

  useEffect(() => {
    auth.getAuth().onAuthStateChanged((user) => {
      if (user && firebase.user) {
        return setIsAuthenticated(true);
      }
      // history.push("/");

      return setIsAuthenticated(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase.user]);

  return isAuthenticated;
};

export default useTwitterAuthentication;
