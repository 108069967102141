// eslint-disable-next-line import/no-anonymous-default-export
export default {
  supportedChains: [250, 43114],
  defaultChain: 250,
  ftm: {
    providers: [
      {
        http: "https://rpc.ftm.tools/",
        // for subscriptions
        ws: "",
      },
    ],
    // JSON-RPC endpoint
    rpc: "https://rpc.ftm.tools/",
    // used in links pointing to fantom explorer
    explorerUrl: "https://ftmscan.com/",
    // used in links pointing to validators
    explorerUrl2: "https://ftmscan.com/",
    // used in links pointing to fantom explorer's transaction detail
    explorerTransactionPath: "tx",
    // chain id for testnet
    chainId: "250",
    name: "Fantom",
  },
  avalanche: {
    providers: [
      {
        http: "https://api.avax.network/ext/bc/C/rpc",
        // for subscriptions
        ws: "",
      },
    ],
    // JSON-RPC endpoint
    rpc: "https://api.avax.network/ext/bc/C/rpc",
    // used in links pointing to avalanche explorer
    explorerUrl: "https://cchain.explorer.avax.network/",
    explorerTransactionPath: "tx",
    // chain id for testnet
    chainId: "43114",
    name: "Avalanche",
  },
};
