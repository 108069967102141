import React from "react";

const ErrorMessageChallenge: React.FC<any> = ({ errorMessage }) => {
  return (
    <div className="error-message">
      <p>{errorMessage}</p>
    </div>
  );
};

export default ErrorMessageChallenge;
