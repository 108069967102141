import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import "./Marketplace.scss";
import useApi, { ALL_CARDS, SHOP_CARDS } from "../../hooks/useMetadataApi";
import useOverdriveContract, {
  OD_FACTORY_METHODS,
  OD_STORE_METHODS,
} from "../../hooks/useOverdriveContract";
import useWalletProvider from "../../hooks/useWalletProvider";
import { formatHexToBN, weiToUnit } from "../../utils/conversions";
import { BigNumber } from "@ethersproject/bignumber";
import image1 from "./1.jpg";
import image2 from "./2.jpg";
import image3 from "./3.jpg";
import config from "../../config/config.prod";

const products = [
  {
    cardId: 1,
    cardCost: {
      type: "BigNumber",
      hex: "0x0de0b6b3a7640000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/1",
      image: image1,
      name: "Pepemander",
      description: "Pepemon Gen 1 Starters - Pepemander [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 2,
    cardCost: {
      type: "BigNumber",
      hex: "0x0f43fc2c04ee0000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/2",
      image: image2,
      name: "Pepertle",
      description: "Pepemon Gen 1 Starters - Pepertle [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 3,
    cardCost: {
      type: "BigNumber",
      hex: "0x1f399b1438a10000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/3",
      image: image3,
      name: "Pepesaur",
      description: "Pepemon Gen 1 Starters - Pepesaur [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 1,
    cardCost: {
      type: "BigNumber",
      hex: "0x0de0b6b3a7640000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/1",
      image: image1,
      name: "Pepemander",
      description: "Pepemon Gen 1 Starters - Pepemander [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 2,
    cardCost: {
      type: "BigNumber",
      hex: "0x0f43fc2c04ee0000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/2",
      image: image2,
      name: "Pepertle",
      description: "Pepemon Gen 1 Starters - Pepertle [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
  {
    cardId: 3,
    cardCost: {
      type: "BigNumber",
      hex: "0x1f399b1438a10000",
    },
    metadata: {
      pool: {
        name: "root",
        points: 1,
      },
      external_url: "https://pepemon.finance/api/cards/3",
      image: image3,
      name: "Pepesaur",
      description: "Pepemon Gen 1 Starters - Pepesaur [Pixel ver.]",
      attributes: [
        {
          trait_type: "Set",
          value: "Gen 1 starters",
        },
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Version",
          value: "Basic",
        },
        {
          trait_type: "Artist",
          value: "푸푸히",
        },
        {
          trait_type: "Type",
          value: "Collectors Edition",
        },
        {
          trait_type: "Tag",
          value: "pepemon.world",
        },
        {
          display_type: "date",
          trait_type: "birthday",
          value: 1604444400,
        },
        {
          trait_type: "Max Supply",
          value: "100",
        },
      ],
    },
  },
];

const ProductsContainer: React.FC<any> = ({ products }) => {
  const history = useHistory();
  const getRarity = (metadata: any) =>
    metadata &&
    metadata.attributes &&
    metadata.attributes.find((attr: any) => attr.trait_type === "Rarity").value;
  return (
    <div className="row no-gutters">
      {products.map((product: any) => {
        return (
          <div
            key={`shop-${product.cardId}-${Math.random()}`}
            className="col-xl-4 col-lg-4 col-md-6 col-12"
          >
            <div
              className="nft-item box"
              onClick={() => history.push(`product/${product.cardId}`)}
            >
              <div className="nft-details nft-details-top d-flex justify-content-between">
                <div>For sale</div>
                <div>{weiToUnit(BigNumber.from(product.cardCost))}</div>
              </div>
              <div className="nft-image-wrap">
                <img
                  style={{
                    backgroundSize: "contain",
                    height: "100%",
                    minWidth: "unset",
                  }}
                  className="nft-image"
                  src={product.metadata.image}
                  alt={product.metadata.name}
                />
              </div>
              <div className="nft-details nft-details-bottom d-flex flex-column">
                <h3>{product.metadata.name}</h3>
                <div>{getRarity(product.metadata)}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Marketplace: React.FC<any> = () => {
  const [nftStoreCollection, setNftStoreCollection] = useState([]);
  const { apiData } = useApi();

  const { callODStoreMethod } = useOverdriveContract();
  const { wallet } = useWalletProvider();

  useEffect(() => {
    if (
      wallet.account &&
      config.supportedChains.includes(parseInt(wallet.chainId)) &&
      parseInt(wallet.chainId) === parseInt(wallet.web3NetworkSelected)
    ) {
      const cardCostPromises = SHOP_CARDS.map((cardId) =>
        callODStoreMethod(OD_STORE_METHODS.CARD_COSTS, [cardId])
      );
      Promise.all(cardCostPromises).then((result) => {
        if (!result) {
          return;
        }
        const { metadata } = apiData;
        const cards = result.map((cardCost: any, index: number) => ({
          cardId: SHOP_CARDS[index],
          cardCost: cardCost ? formatHexToBN(cardCost) : 0,
          metadata: {
            ...metadata[SHOP_CARDS[index]].data,
          },
        }));
        // TODO remove doubles implemented for demo
        setNftStoreCollection([
          ...cards.filter((card: any) => card.cardCost.gt(BigNumber.from(0))),
          ...cards.filter((card: any) => card.cardCost.gt(BigNumber.from(0))),
        ]);
      });
    }
  }, [wallet.account, wallet.chainId]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex justify-content-center mt-5">
          <h1>Coming soon</h1>
        </div>
      </div>
      {/*<ProductsContainer products={nftStoreCollection} />*/}
    </div>
  );
};

export default Marketplace;
