import React, { useEffect, useState } from "react";

const useResizeWindow = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, [setWindowWidth]);

  return { windowWidth };
};

export default useResizeWindow;
