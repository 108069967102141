import React, { useEffect, useState } from "react";
import useOverdriveApi from "../../hooks/useOverdriveApi";
import Loader from "../../components/Loader/Loader";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const FeedSelector: React.FC<any> = ({
  challengeCategory,
  setChallengeCategory,
  animateChallengeList,
}) => {
  return (
    <div className="box feed-selector challenges-feed">
      <button
        style={{
          backgroundColor:
            challengeCategory === "active" ? "#ffffff" : "transparent",
          color:
            challengeCategory === "active"
              ? "#000000"
              : "rgba(255,255,255,0.3)",
        }}
        onClick={() => {
          setChallengeCategory("active");
          animateChallengeList();
        }}
      >
        Active
      </button>
      <button
        style={{
          backgroundColor:
            challengeCategory === "completed" ? "#ffffff" : "transparent",
          color:
            challengeCategory === "completed"
              ? "#000000"
              : "rgba(255,255,255,0.3)",
        }}
        onClick={() => {
          setChallengeCategory("completed");
          animateChallengeList();
        }}
      >
        Completed
      </button>
      <button
        style={{
          backgroundColor:
            challengeCategory === "expiresAt" ? "#ffffff" : "transparent",
          color:
            challengeCategory === "expiresAt"
              ? "#000000"
              : "rgba(255,255,255,0.3)",
        }}
        onClick={() => {
          setChallengeCategory("expiresAt");
          animateChallengeList();
        }}
      >
        expired
      </button>
    </div>
  );
};

const ChallengeBox: React.FC<any> = ({ challenge }) => {
  const history = useHistory();
  const expiresAt = () => {
    let today = new Date();

    let diff = challenge.expiresAt - today.getTime();
    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));

    if (diff === 1 && challenge.active) {
      return `Expires in ${diff} day`;
    } else if (diff > 1 && challenge.active) {
      return `Expires in ${diff} days`;
    } else if (challenge.active) {
      return `Active`;
    } else {
      return "Expired";
    }
  };
  return (
    <div className="col-md-6 col-12 mb-4 challenge-item-wrap limit-width">
      <div
        onClick={() => history.push(`challenge/${challenge.slug}`)}
        className="challenge-item box"
      >
        <div className="text-wrap p-4">
          <h2>{challenge.title}</h2>
          <p>{challenge.excerpt}</p>
        </div>
        <div className="box-list d-flex flex-row">
          <div className="d-flex box-list-item p-2 col justify-content-center align-items-center">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#DDFC0F" />
              <path
                d="M6 22.5534V18.9326C6 18.4308 6.19457 17.9589 6.54778 17.6042L9.58745 14.5503C9.93551 14.2006 10.4174 14 10.9098 14H17.0138L17.9497 17.447H11.558L9.43098 19.5829V22.5534H6Z"
                fill="black"
              />
              <path
                d="M10.3655 25.9999L9.42969 22.5533H15.8278L17.9548 20.4173V17.4512H21.3857V21.0676C21.3857 21.5695 21.1913 22.0411 20.8382 22.3961L17.7984 25.4499C17.4503 25.7993 16.9683 25.9999 16.4761 25.9999H10.3655Z"
                fill="black"
              />
              <path
                d="M21.3848 17.4485V14.0176H29.6533L30.585 17.4485H21.3848Z"
                fill="black"
              />
              <path
                d="M21.3848 25.9981V22.5672H28.4677L30.5874 20.4385L30.5849 17.4463H34.0026V21.0857C34.0026 21.5852 33.809 22.0549 33.4574 22.4081L30.4291 25.4502C30.0827 25.7985 29.6028 25.9981 29.1128 25.9981H21.3848Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="d-flex box-list-item p-2 col justify-content-center align-items-center">
            {challenge.type}
          </div>
          <div className="d-flex box-list-item p-2 col justify-content-center align-items-center">
            {expiresAt()}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChallengeList: React.FC<any> = ({ challenges, challengeCategory }) => {
  const filterChallenges = (value: any) => {
    if (challengeCategory === "expiresAt") {
      return value.active === false;
    }
    if (challengeCategory === "completed") {
      return value.active === true && value.completed === true;
    }
    if (challengeCategory === "active") {
      return value.active === true && value.completed === false;
    }
  };
  const result = challenges.data.filter(filterChallenges);
  if (result.length === 0) {
    return <div className="col">No challenges has been added.</div>;
  }
  return result.map((challenge: any) => {
    return <ChallengeBox key={challenge.id} challenge={challenge} />;
  });
};

const Challenges: React.FC<any> = () => {
  const { apiData, get } = useOverdriveApi();
  const [challengeCategory, setChallengeCategory] = useState<
    "active" | "completed" | "expiresAt"
  >("active");
  const challengeUser = apiData["challengeUser"];
  ReactGA.initialize("UA-193287738-2");
  ReactGA.pageview(window.location.pathname + window.location.search);

  const animateChallengeList = () => {
    setTimeout(() => {
      Array.from(document.querySelectorAll(".challenge-item")).map(
        (item: any, index: number) => {
          setTimeout(
            (item: any) => {
              item.classList.add("animated");
            },
            70 * (index + 1),
            item
          );
        }
      );
    }, 200);
  };

  useEffect(() => {
    get("challengeUser")
      .then(() => animateChallengeList())
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex mb-5">
          <FeedSelector
            challengeCategory={challengeCategory}
            setChallengeCategory={setChallengeCategory}
            animateChallengeList={animateChallengeList}
          />
        </div>
      </div>
      <div className="row">
        {challengeUser && challengeUser.data ? (
          <ChallengeList
            challengeCategory={challengeCategory}
            challenges={challengeUser.data}
          />
        ) : challengeUser && challengeUser.status === "pending" ? (
          <div className="col">
            <Loader />
          </div>
        ) : (
          <div className="col">
            {challengeUser && challengeUser.error.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Challenges;
