/**
 * See all ids below
 * https://ethereum.stackexchange.com/questions/17051/how-to-select-a-network-id-or-is-there-a-list-of-network-ids
 */
export const FANTOM_ID = 250;
export const FANTOM_TEST_ID = 4002;
export const AVALANCHE_ID = 43114;
export const AVALANCHE_TEST_ID = 43113;

const commonContracts = {
  factory: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  router01: "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a",
  router02: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
};

export default {
  [FANTOM_TEST_ID]: {
    pairs: {
      // "DRIVE-WFTM": "0x8F609d85ebC64316B0B2f9E53c11b4e48B7A06d2",
    },
    tokens: {
      drive: "0xFCeB4dcDd78c577936D7fFdDB68003D34468d839",
    },
    overdriveFactory: "0xbff87bC423dfB8B1e86B85d14Ab381794b759f63",
    overdriveStore: "0x8D40Be14b1E985e8c50d142C292c599C8927EED1",
    overdriveBoostStake: "0xe50A1070b5919C910584314E64Dc72b675F73780",
    ...commonContracts,
  },
  [FANTOM_ID]: {
    pairs: {
      // "DRIVE-WFTM": "0x8F609d85ebC64316B0B2f9E53c11b4e48B7A06d2",
    },
    tokens: {
      drive: "",
    },
    overdriveFactory: "",
    overdriveStore: "",
    overdriveBoostStake: "",
    ...commonContracts,
  },
  [AVALANCHE_TEST_ID]: {
    pairs: {
      // "DRIVE-WFTM": "0x8F609d85ebC64316B0B2f9E53c11b4e48B7A06d2",
    },
    tokens: {
      drive: "",
    },
    overdriveFactory: "",
    overdriveStore: "",
    overdriveBoostStake: "",
    ...commonContracts,
  },
  [AVALANCHE_ID]: {
    pairs: {
      // "DRIVE-WFTM": "0x8F609d85ebC64316B0B2f9E53c11b4e48B7A06d2",
    },
    tokens: {
      drive: "",
    },
    overdriveFactory: "",
    overdriveStore: "",
    overdriveBoostStake: "",
    ...commonContracts,
  },
};
