import React, { useEffect, useState } from "react";

const TweetButton: React.FC<any> = ({
  handleClick,
  activated,
  variant,
  disabled,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsClicked(false);
    }, 2000);
  }, []);
  return (
    <button
      disabled={disabled}
      className={`button tweet-action box ${variant}
      ${activated ? "activated" : ""} 
      ${isClicked ? "clickanimation" : ""}`}
      onClick={() => {
        setIsClicked(true);
        setTimeout(
          (handleClick) => {
            handleClick();
          },
          250,
          handleClick
        );
      }}
    >
      {variant === "like" ? (
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6316 67.3684H59.84L67.3684 59.84V35.0877H51.2281C47.7398 35.0877 44.9123 32.2602 44.9123 28.7719V12.6316H20.16L12.6316 20.16V67.3684ZM62.4561 80H6.31579C2.82756 80 0 77.1724 0 73.6842V17.5439C0 15.8685 0.665263 14.2625 1.84982 13.0779L13.0779 1.84982C14.2625 0.665263 15.8685 0 17.5439 0H51.2281C54.7162 0 57.5439 2.82756 57.5439 6.31579V22.4561H73.6842C77.1723 22.4561 80 25.2837 80 28.7719V62.4561C80 64.131 79.3347 65.738 78.1502 66.9221L66.9221 78.1502C65.7375 79.3347 64.1315 80 62.4561 80Z"
            fill="#ED3E08"
          />
        </svg>
      ) : null}
      {variant === "quote" ? (
        <svg
          width="111"
          height="80"
          viewBox="0 0 111 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.5635 46.3158C23.1799 46.3158 24.7963 45.6991 26.0295 44.4659L43.127 27.3684L34.1951 18.4365L27.8793 24.7523V17.353L32.6007 12.6316H82.6161V29.4737H95.2477V6.31579C95.2477 2.82756 92.4201 0 88.9319 0H29.9845C28.3092 0 26.7031 0.665263 25.5186 1.84982L17.0975 10.2709C15.913 11.4554 15.2477 13.0615 15.2477 14.7368V24.7523L8.93193 18.4365L0 27.3684L17.0975 44.4659C18.3307 45.6991 19.9471 46.3158 21.5635 46.3158Z"
            fill="#D2FF00"
          />
          <path
            d="M110.494 52.632L93.3962 35.5344C90.9303 33.068 86.9308 33.068 84.4643 35.5344L67.3668 52.632L76.2987 61.5639L82.6145 55.2481V62.6474L77.8931 67.3688H27.8777V50.5267H15.2461V73.6846C15.2461 77.1728 18.0738 80.0004 21.5619 80.0004H80.5092C82.1846 80.0004 83.7906 79.3351 84.9752 78.1506L93.3962 69.7295C94.5808 68.5454 95.2461 66.9384 95.2461 65.2635V55.2481L101.562 61.5639L110.494 52.632Z"
            fill="#D2FF00"
          />
        </svg>
      ) : null}
      {variant === "comment" ? (
        <svg
          width="98"
          height="80"
          viewBox="0 0 98 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.2059 13.3333H84.4444V0H24.4444C22.676 0 20.9807 0.702222 19.7304 1.95259L1.95259 19.7304C0.702222 20.9807 0 22.676 0 24.4444V42.963H13.3333V27.2059L27.2059 13.3333Z"
            fill="#6000FF"
          />
          <path
            d="M84.4451 13.333V29.09L70.5725 42.9626H43.7044C41.9364 42.9626 40.2402 43.6649 38.9903 44.9152L26.6673 57.2382V42.9626H13.334V73.333C13.334 76.0293 14.9582 78.4604 17.4495 79.4925C18.2742 79.8338 19.1404 79.9997 19.9992 79.9997C21.734 79.9997 23.4392 79.3226 24.7147 78.0471L46.4658 56.296H73.334C75.1019 56.296 76.7982 55.5938 78.0481 54.3434L95.8258 36.5656C97.0762 35.3152 97.7784 33.6199 97.7784 31.8515V13.333H84.4451Z"
            fill="#6000FF"
          />
        </svg>
      ) : null}
      {variant === "skip" ? (
        <svg
          width="84"
          height="80"
          viewBox="0 0 84 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M81.9395 35.3175L46.622 0L37.257 9.36502L61.27 33.378H15.4514C13.6953 33.378 12.0104 34.0755 10.7689 35.3175L1.93952 44.1469C0.697521 45.3884 0 47.0734 0 48.8294V62.0734C0 65.7308 2.96467 68.6955 6.62203 68.6955H28.6955V55.4514H13.2441V51.5724L18.1944 46.622H61.27L37.257 70.635L46.622 80L81.9395 44.6825C84.5255 42.0961 84.5255 37.9036 81.9395 35.3175Z"
            fill="white"
          />
        </svg>
      ) : null}
    </button>
  );
};

export default TweetButton;
