import React, { useState } from "react";
import { Box1, Header2, Header3 } from "../../components";

const TabLink: React.FC<any> = ({
  text,
  active,
  onClick,
}: {
  text: string;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        paddingBottom: "1rem",
        textAlign: "center",
        borderBottom: active
          ? "2px solid rgb(8, 110, 235)"
          : "2px solid #8e8e96",
        display: "inline-block",
        width: "150px",
        cursor: "pointer",
      }}
    >
      <a
        style={{
          fontSize: "18px",
          color: active ? "rgb(8, 110, 235)" : "#8e8e96",
          textTransform: "uppercase",
        }}
      >
        {text}
      </a>
    </div>
  );
};

export const Tabs: React.FC<any> = ({ items }: { items: string[] }) => {
  const [activeTab, setActiveTab] = useState(items[0]);

  return (
    <Header3 style={{ margin: "1rem 0rem" }}>
      {items.map((item) => (
        <TabLink
          key={item}
          text={item}
          active={item === activeTab}
          onClick={() => {
            setActiveTab(item);
          }}
        />
      ))}
    </Header3>
  );
};
