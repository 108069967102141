// @ts-ignore
import { abis, addresses } from "@overdrive/contracts";
import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { getChainId } from "./wallet";
import config from "../config/config.prod";

export const send = async (
  provider: any,
  callback: () => Promise<any>,
  dispatch: any
) => {
  let txHash = null;
  try {
    const { hash } = await callback();
    txHash = hash;
    dispatch({
      type: "transactionPending",
      id: hash,
    });
    return new Promise((resolve) => {
      provider.once(hash, (transaction: any) => {
        dispatch({
          type: "transactionCompleted",
          id: hash,
          transaction,
        });
        console.info("COMPLETED TX", transaction);
        resolve(hash);
      });
    });
  } catch (err) {
    dispatch({
      type: "transactionError",
      error: err,
      id: txHash,
    });
    throw err;
  }
};

export enum OverdriveContract {
  DRIVE = "drive",
  OD_FACTORY = "overdriveFactory",
  OD_STORE = "overdriveStore",
  OD_BOOST_STAKE = "overdriveBoostStake",
}

export const loadContract = async (
  chainId: number,
  contract: OverdriveContract,
  signer: any
) => {
  if (!addresses[chainId].tokens[contract] && !addresses[chainId][contract]) {
    console.warn(`Failed to load contract ${contract} on chain ${chainId}`);
    return;
  }

  return new Contract(
    addresses[chainId].tokens[contract] || addresses[chainId][contract],
    abis[contract],
    signer
  );
};

export const loadInitialContracts = async (
  chainId: number,
  provider: Web3Provider
) => {
  if (!config.supportedChains.includes(chainId)) {
    console.warn(`[Unsupported chain: ${chainId}] No contracts loaded!`);
    return;
  }

  const signer = provider.getSigner();
  return new Map([
    [
      OverdriveContract.DRIVE,
      await loadContract(chainId, OverdriveContract.DRIVE, signer),
    ],
    [
      OverdriveContract.OD_FACTORY,
      await loadContract(chainId, OverdriveContract.OD_FACTORY, signer),
    ],
    [
      OverdriveContract.OD_STORE,
      await loadContract(chainId, OverdriveContract.OD_STORE, signer),
    ],
    [
      OverdriveContract.OD_BOOST_STAKE,
      await loadContract(chainId, OverdriveContract.OD_BOOST_STAKE, signer),
    ],
  ]);
};

export const loadERC20Contract = async (
  contractAddress: string,
  provider: any
) => {
  const chainId = await getChainId(provider);
  const signer = provider.getSigner();
  if (!config.supportedChains.includes(chainId)) {
    console.warn(`[Unsupported chain: ${chainId}] No contracts loaded!`);
    return;
  }

  if (!signer) {
    return;
  }
  if (!contractAddress) {
    console.error("[loadERC20Contract] missing contract address");
    return;
  }

  return new Contract(contractAddress, abis.erc20.abi, signer);
};
