import React from "react";
import { ButtonWithCutoff } from "../index";
import MetamaskFox from "../../assets/img/metamask_fox.svg";

const WalletButton: React.FC<any> = ({
  wallet,
  loadWeb3Modal,
  logoutOfWeb3Modal,
}: any) => {
  return (
    <button
      className="button metamask-login"
      onClick={() => {
        if (!wallet.account) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      <img src={MetamaskFox} style={{ paddingRight: ".5rem" }} />
      {!wallet.account ? "Connect MetaMask" : "Disconnect Wallet"}
    </button>
  );
};

export default WalletButton;
