import { useContext } from "react";
import axios from "axios";
import { OverdriveApiContext } from "../context/OverdriveApiProvider";
import { OverdriveContext } from "../context/OverdriveProvider";
import { FirebaseContext } from "../context/FirebaseProvider";

const authHandler = (response: any, dispatch: any = {}) => {
  if ([200, 201].includes(response.status)) {
    return dispatch.dispatchOd({
      type: "setJWT",
      jwt: response.data,
      existingAccount: response.status === 200,
      loginFailed: false,
    });
  }
  return dispatch.dispatchOd({
    type: "setJWT",
    jwt: null,
    existingAccount: false,
    loginFailed: true,
  });
};

const BASE_URI: string =
  process.env.REACT_APP_API_BASE_URI || "http://localhost:9000/v1/";
const METHODS: any = {
  affiliateID: ["affiliateID", null],
  auth: ["auth/user", authHandler],
  user: ["user", null],
  userMe: ["userMe", null],
  userStat: ["userStat", null],
  campaign: ["campaign", null],
  campaignActive: ["campaignActive", null],
  campaignTweets: ["campaignTweets", null],
  skipTweet: ["skipTweet", null],
  likeTweet: ["likeTweet", null],
  quoteTweet: ["quoteTweet", null],
  shareTweet: ["shareTweet", null],
  commentTweet: ["commentTweet", null],
  newTweet: ["newTweet", null],
  campaignBySlug: ["campaignBySlug", null],
  affiliate: ["affiliate", null],
  levelAll: ["levelAll", null],
  userLeaderboard: ["/user/leaderboard", null],
  challengeUser: ["challenge/user", null],
  challengeBySlug: ["/challenge/slug", null],
  challengeSubmitWork: ["challengeSubmitWork", null],
  challengeSubmitSocial: ["challengeSubmitSocial", null],
  challengeSubmitCode: ["challengeSubmitCode", null],
};

const paramBuilder = (params: string[]) => {
  if (!params.length) {
    return "";
  }

  return `/${params.join("/")}`;
};
const queryParamBuilder = (queryParams: string[]) =>
  queryParams.length ? `?${queryParams.join("&")}` : "";
const uriBuilder = (
  method: string,
  params: string[],
  queryParams: string[]
) => {
  switch (method) {
    case "affiliateID":
      return `${BASE_URI}affiliate${paramBuilder(params)}`;
    case "userMe":
      return `${BASE_URI}user/me`;
    case "userStat":
      return `${BASE_URI}user${paramBuilder(params)}/stats`;
    case "campaign":
      return `${BASE_URI}campaign/all`;
    case "campaignTweets":
      return `${BASE_URI}campaign${paramBuilder(params)}`;
    case "campaignBySlug":
      return `${BASE_URI}campaign/slug${paramBuilder(params)}`;
    case "campaignActive":
      return `${BASE_URI}campaign/active`;
    case "skipTweet":
      return `${BASE_URI}tweet${paramBuilder(params)}/skip`;
    case "likeTweet":
      return `${BASE_URI}tweet${paramBuilder(params)}/like`;
    case "quoteTweet":
      return `${BASE_URI}tweet${paramBuilder(params)}/quote`;
    case "shareTweet":
      return `${BASE_URI}tweet${paramBuilder(params)}/share`;
    case "commentTweet":
      return `${BASE_URI}tweet${paramBuilder(params)}/comment`;
    case "newTweet":
      return `${BASE_URI}tweet${paramBuilder(params)}/post`;
    case "affiliate":
      return `${BASE_URI}affiliate/all`;
    case "levelAll":
      return `${BASE_URI}level/all`;
    case "challengeUser":
      return `${BASE_URI}challenge/user/all`;
    case "challengeBySlug":
      return `${BASE_URI}challenge/user/slug${paramBuilder(params)}`;
    case "challengeSubmitWork":
      return `${BASE_URI}submission/challenge${paramBuilder(params)}/work`;
    case "challengeSubmitSocial":
      return `${BASE_URI}submission/challenge${paramBuilder(params)}/social`;
    case "challengeSubmitCode":
      return `${BASE_URI}submission/challenge${paramBuilder(params)}/code`;
    case "userLeaderboard":
      return `${BASE_URI}user/leaderboard`;
    default:
      return `${BASE_URI}${METHODS[method][0]}${paramBuilder(
        params
      )}${queryParamBuilder(queryParams)}`;
  }
};

const useOverdriveApi = () => {
  const [apiData, dispatchApi] = useContext(OverdriveApiContext);
  const [, dispatchOd] = useContext(OverdriveContext);
  const [firebase, dispatchFb] = useContext(FirebaseContext);

  const rest = async (
    method: string,
    body: any = null,
    params: string[] = [],
    queryParams: string[] = []
  ) => {
    if (apiData[method] && apiData[method].status === "pending") {
      return;
    }

    const authorization =
      method === "auth" ? firebase.user.za : localStorage.getItem("token");
    let response = null;

    try {
      dispatchApi({
        method,
        status: "pending",
      });

      response = body
        ? await axios.post(uriBuilder(method, params, queryParams), body, {
            headers: {
              Authorization: "Bearer " + authorization,
            },
          })
        : await axios.get(uriBuilder(method, params, queryParams), {
            headers: {
              Authorization: "Bearer " + authorization,
            },
          });

      if (METHODS[method][1]) {
        METHODS[method][1](response, { dispatchOd, dispatchFb });
      }

      const action = {
        method,
        status: "completed",
        data: response.data,
      };

      dispatchApi(action);
    } catch (err) {
      if (METHODS[method][1]) {
        METHODS[method][1]({ status: err.status }, { dispatchOd, dispatchFb });
      }
      dispatchApi({
        method,
        status: "failed",
        error: err,
      });
    }
    return response;
  };

  // TODO clean up.. copied REST version for now
  const formData = async (
    method: string,
    data: any,
    params: string[],
    queryParams: string[]
  ) => {
    try {
      const response = await axios.post(
        uriBuilder(method, params, queryParams),
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const action = {
        method,
        status: "completed",
        data: response.data,
      };

      dispatchApi(action);
    } catch (err) {
      dispatchApi({
        method,
        status: "failed",
        error: err,
      });
    }
  };

  return {
    apiData,
    get: (method: string, params?: string[], queryParams?: string[]) =>
      rest(method, null, params, queryParams),
    post: (
      method: string,
      body: any,
      params?: string[],
      queryParams?: string[]
    ) => rest(method, body, params, queryParams),
    formData: (
      method: string,
      data: any,
      params?: string[],
      queryParams?: string[]
    ) => formData(method, data, params, queryParams),
  };
};

export default useOverdriveApi;
