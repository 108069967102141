import { black, white, grey } from "./colors";

const theme = {
  borderRadius: 12,
  breakpoints: {
    mobile: 576,
    tablet: 768,
    desktop: 992,
    ultra: 1200,
  },
  color: {
    black,
    white,
    grey,
    dark: {
      1: "#181821",
      2: "#3A486B",
      3: "rgba(59, 58, 107, 0.2)",
      4: "#3D476F",
    },
    0: "#181821",
    1: "#E63946",
    2: "#F1FAEE",
    3: "#A8DADC",
    4: "#207CF4",
    5: "#1D3557",
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 200,
};

export default theme;
