import erc20 from "./abis/erc20.json";
import factory from "./abis/factory.json";
import pair from "./abis/pair.json";
import router01 from "./abis/router01.json";
import router02 from "./abis/router02.json";
import drive from "./abis/odToken.json";
import overdriveFactory from "./abis/odFactory.json";
import overdriveStore from "./abis/odStore.json";
import overdriveBoostStake from "./abis/odBoostStake.json";

export default {
  erc20,
  factory,
  pair,
  router01,
  router02,
  drive,
  overdriveFactory,
  overdriveStore,
  overdriveBoostStake,
};
