import React, { useEffect, useState } from "react";
import useOverdriveApi from "../../hooks/useOverdriveApi";
import { useHistory } from "react-router-dom";
import LogoutButton from "../../components/LogoutButton";
import "./profile.scss";
import ReactGA from "react-ga";

const Profile: React.FC<any> = () => {
  const { get } = useOverdriveApi();
  const [userStat, setUserStat] = useState(null);
  const [profile, setProfile] = useState(null);
  ReactGA.initialize("UA-193287738-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  //const history = useHistory();

  useEffect(() => {
    if (!profile) {
      get("userMe")
        .then((response) => {
          setProfile(response?.data);
        })
        .catch((err) => console.error(err));
    }
  }, [profile]);

  useEffect(() => {
    if (!userStat && profile) {
      get("userStat", [profile.id])
        .then((response) => {
          setUserStat(response?.data);
        })
        .catch((err) => console.error(err));
    }
  }, [userStat, profile]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="profile-banner">
            <img src={profile ? profile.profile.bannerUrl : ""} alt="" />
          </div>
          <div className="profile-details d-flex flex-row">
            <div className="image">
              <img src={profile ? profile.profile.photoUrl : ""} alt="" />
            </div>
            <div className="user-info">
              <div>{profile ? profile.profile.displayName : ""}</div>
              <div>@{profile ? profile.profile.screenName : ""}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ maxWidth: "1000px", marginLeft: "0" }}>
        <div className="col-4 col-md">
          <div className="profile-stat-item d-flex flex-column mb-3">
            <span>ENGAGEMENTS</span>
            {userStat ? userStat.engagement : 0}
          </div>
        </div>
        <div className="col-4 col-md">
          <div className="profile-stat-item d-flex flex-column mb-3">
            <span>POSTS</span>
            {userStat ? userStat.posts : 0}
          </div>
        </div>
        <div className="col-4 col-md">
          <div className="profile-stat-item d-flex flex-column mb-3">
            <span>LIKES</span> 0
          </div>
        </div>
        <div className="col-4 col-md">
          <div className="profile-stat-item d-flex flex-column">
            <span>IMPRESSIONS</span>
            {userStat ? userStat.impressions : 0}
          </div>
        </div>
        <div className="col-4 col-md">
          <div className="profile-stat-item d-flex flex-column">
            <span>PROFILE VISITS</span> 0
          </div>
        </div>
        <div className="col-4 col-md">
          <div className="profile-stat-item d-flex flex-column">
            <span>FOLLOWERS</span>
            {userStat ? userStat.followers : 0}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-end mb-5 mt-5">
          <LogoutButton />
        </div>
      </div>
    </div>
  );
};

export default Profile;
