import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useOverdriveApi from "../../hooks/useOverdriveApi";
import Loader from "../../components/Loader/Loader";
import "./campaigns.scss";
import ReactGA from "react-ga";

// const Masonry = require("masonry-layout");

const CampaignBlock: React.FC<any> = ({ campaign }) => {
  const history = useHistory();
  const logo =
    process.env.REACT_APP_STORAGE_URL +
    "/affiliates/" +
    campaign.affiliate.logo;
  const formatDate = (value: any) => {
    let d = new Date(parseInt(value));
    return d.toDateString();
  };
  const cardTitle = (size: any, name: any) => {
    if (size === "lg") return <h3>{name}</h3>;
    else return <p>{name}</p>;
  };
  const cardDescription = (size: any, description: any) => {
    if (size === "lg") return <p>{description}</p>;
  };

  return (
    <div className="col-xl-4 col-md-6 col-sm-12 col-12 campaign-item-col">
      <div
        className={`p-4 campaign-item d-block box d-flex flex-column ${campaign.size}`}
        onClick={() => history.push(`campaign/${campaign.slug}`)}
      >
        <div className="logo d-flex align-content-center">
          <img src={logo} alt={campaign.name} />
        </div>
        {cardTitle(campaign.size, campaign.name)}
        {cardDescription(campaign.size, campaign.description)}
        <p className="expires">Ends {formatDate(campaign.expiresAt)}</p>
      </div>
    </div>
  );
};

const CampaignList: React.FC<any> = ({ campaigns }) => {
  const campaignsCopy = campaigns.slice();
  campaignsCopy.sort(function (a: any, b: any) {
    return a.order - b.order;
  });
  return campaignsCopy.map((campaign: any) => {
    return <CampaignBlock key={campaign.id} campaign={campaign} />;
  });
};

const Campaigns: React.FC<any> = () => {
  const { apiData, get } = useOverdriveApi();
  const campaigns = apiData["campaignActive"];
  ReactGA.initialize("UA-193287738-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  useEffect(() => {
    get("campaignActive")
      .then(() => {
        Array.from(document.querySelectorAll(".campaign-item")).map(
          (item: any, index: number) => {
            setTimeout(
              (item: any) => {
                item.classList.add("animated");
              },
              70 * (index + 1),
              item
            );
          }
        );
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {campaigns && campaigns.data ? (
          <>
            <CampaignList campaigns={campaigns.data} />
          </>
        ) : campaigns && campaigns.status === "pending" ? (
          <div className="col">
            <Loader />
          </div>
        ) : (
          <div className="col">{campaigns && campaigns.error.message}</div>
        )}
      </div>
    </div>
  );
};

export default Campaigns;
