import React from "react";
import styled from "styled-components";

export interface LoginStepperProps {
  currentStep?: number;
}
const LoginStepper: React.FC<LoginStepperProps> = ({ currentStep }) => {
  return (
    <LoginStepperContainer>
      <StepContainer>
        <Step active={currentStep === 1}>1</Step>
        <Line />
        <Step active={currentStep === 2}>2</Step>
      </StepContainer>
      <StepTextContainer>
        <StepText>Sign up with Twitter</StepText>
        <div style={{ margin: "1.3rem" }} />
        <StepText>Connect MetaMask</StepText>
      </StepTextContainer>
    </LoginStepperContainer>
  );
};

export interface StepProps {
  active: boolean;
}

const LoginStepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

const Step = styled.div<StepProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  border: 2px solid #fff;
  border: ${(props) => props.active && "2px solid #DDFC0F !important"};
`;

const Line = styled.div`
  height: 1px;
  width: 120px;
  border-bottom: 2px solid #ffffff;
`;

const StepText = styled.div`
  padding: 0.5rem;
`;

export default LoginStepper;
