import React, { useEffect } from "react";
import Modal from "../../components/Modal";
import useTransaction from "../../hooks/useTransaction";

const TransactionModal: React.FC<any> = ({ onDismiss }) => {
  const { transaction, dispatchTx } = useTransaction();

  useEffect(() => {
    let timeout = setTimeout(() => {
      dispatchTx({
        type: "reset",
      });
      onDismiss();
    }, 10000);
    return () => clearTimeout(timeout);
  }, [onDismiss]);

  return (
    <Modal style={{ background: "transparent" }}>
      <div className="box notify-modal p-3">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center">
              <h3 className="mb-1">Transaction {transaction.state}.</h3>
              <p className="mt-1 d-flex justify-content-center">
                {transaction.state === "pending"
                  ? `Transaction with id ${transaction.id} is broadcasted to the network.`
                  : transaction.state === "completed"
                  ? `Transaction with id ${transaction.id} was executed successfully.`
                  : `Transaction with id ${transaction.id} failed with error: ${transaction.error.message}.`}
              </p>

              <button
                className="button default"
                onClick={() => {
                  dispatchTx({
                    type: "reset",
                  });
                  onDismiss();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionModal;
