import React, { useReducer } from "react";

export const ApiContext = React.createContext(null);

export const ApiProvider: React.FC = ({ children }) => {
  const ApiReducer = (state: any, action: any) => {
    return {
      ...state,
      [action.type]: {
        ...state[action.type],
        [action.id]: {
          status: action.status,
          data: action.data,
          error: action.error,
        },
      },
    };
  };

  const [state, dispatch] = useReducer(ApiReducer, {});

  return (
    <ApiContext.Provider value={[state, dispatch]}>
      {children}
    </ApiContext.Provider>
  );
};
