import React, { useEffect, useState } from "react";
import "./TweetBox.scss";
import like from "./assets/like.svg";
import quote from "./assets/quote.svg";
import reply from "./assets/reply.svg";
import defaultUserImage from "./assets/default.png";

const TweetBox: React.FC<any> = ({ tweet }) => {
  const [imageSRC, setImageSRC] = useState("");
  const hightlightTweetText = (originalText: string) => {
    if (!originalText) {
      return null;
    }

    let cashtags = tweet.entities?.cashtags ? tweet.entities?.cashtags : [];
    let hashtags = tweet.entities?.hashtags ? tweet.entities?.hashtags : [];
    let urls = tweet.entities?.urls ? tweet.entities?.urls : [];
    let mentions = tweet.entities?.mentions ? tweet.entities?.mentions : [];
    let nextLine = [[`\n`, `<br />`, 0]];

    cashtags = cashtags.map((value: any, index: any) => [
      `$${value.tag}`,
      `<span>$${value.tag}</span>`,
    ]);

    hashtags = hashtags.map((value: any, index: any) => [
      `#${value.tag}`,
      `<span>#${value.tag}</span>`,
    ]);

    urls = urls.map((value: any, index: any) => [
      `${value.url}`,
      `<span><a href=${value.expanded_url} target="_blank">${value.display_url}</a></span>`,
    ]);

    mentions = mentions.map((value: any, index: any) => [
      `@${value.username}`,
      `<span>@${value.username}</span>`,
    ]);

    function sortTagsArray(prop: any) {
      return function (a: any, b: any) {
        if (a[prop].length > b[prop].length) {
          return -1;
        } else if (a[prop].length < b[prop].length) {
          return 1;
        }
        return 0;
      };
    }

    const replacers = [
      ...cashtags,
      ...hashtags,
      ...urls,
      ...mentions,
      ...nextLine,
    ].sort(sortTagsArray(0));

    let processText = originalText;

    replacers?.map((val: any) => {
      processText = processText.replaceAll(val[0], val[1]);
    });

    return { __html: processText };
  };
  const checkUserImage = () => {
    if (tweet) {
      fetch(tweet.user.profile_image_url).then((data) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (data.status === 200) {
          setImageSRC(tweet.user.profile_image_url);
        } else {
          setImageSRC(defaultUserImage);
        }
      });
    }
  };
  const createdAt = (createdDate: any) => {
    const today = new Date();
    createdDate = new Date(createdDate);

    const days = Math.abs(
      Math.ceil(
        (createdDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
      )
    );
    const hours = Math.floor(
      (Math.abs(createdDate.getTime() - today.getTime()) / (1000 * 60 * 60)) %
        24
    );
    const minutes = Math.floor(
      (Math.abs(createdDate.getTime() - today.getTime()) / (1000 * 60)) % 60
    );

    if (days > 0) {
      return `${days}d`;
    }
    if (hours > 0 && days === 0) {
      return `${hours}h`;
    }
    return `${minutes}min`;
  };
  useEffect(() => {
    checkUserImage();
  });
  return (
    <>
      {tweet ? (
        <div className="tweet-box d-flex flex-column">
          <div className="tweet-user d-flex flex-row">
            <div className="tweet-user-image">
              <img src={imageSRC} alt={tweet.user.name} />
            </div>
            <div className="tweet-user-info d-flex flex-column mr-auto pr-3">
              <div className="tweet-user-name">{tweet.user.name}</div>
              <div className="tweet-user-username">@{tweet.user.username}</div>
            </div>
            <div className="tweet-createdAt">{createdAt(tweet.created_at)}</div>
          </div>
          <div className="tweet-text">
            <p dangerouslySetInnerHTML={hightlightTweetText(tweet.text)}></p>
          </div>
          {tweet.media ? (
            <div className="tweet-image">
              <div className="tweet-image-wrap">
                <img
                  src={
                    tweet.media?.url
                      ? tweet.media?.url
                      : tweet.media?.preview_image_url
                  }
                />
              </div>
            </div>
          ) : null}
          <div className="tweet-statistics d-flex flex-row mt-auto">
            <div className="d-flex justify-content-center align-content-center align-items-center">
              <img src={reply} className="mr-2" alt="reply" />
              {tweet.public_metrics.reply_count}
            </div>
            <div className="d-flex justify-content-center align-content-center align-items-center">
              <img src={quote} className="mr-2" alt="quote" />
              {tweet.public_metrics.quote_count}
            </div>
            <div className="d-flex justify-content-center align-content-center align-items-center">
              <img src={like} className="mr-2" alt="like" />
              {tweet.public_metrics.like_count}
            </div>
          </div>
        </div>
      ) : (
        <div className="tweet-box d-flex flex-column">
          <div className="tweet-text">
            <p>No tweets available.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default TweetBox;
